@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/menu/_theme.scss" as *;


@mixin kendo-menu--theme() {

    @include kendo-menu--theme-base();

    .k-menu:not(.k-context-menu) {

        // Root items
        > .k-menu-item {

            // Focus state
            &:focus,
            &.k-focus {
                @include fill(
                    $kendo-menu-item-hover-text,
                    $kendo-menu-item-hover-bg,
                    $kendo-menu-item-hover-border,
                    $kendo-menu-item-hover-gradient
                );
            }

        }
    }

    // Subitems
    .k-menu-group,
    .k-context-menu {
        .k-menu-item {

            // Focus state
            &:focus,
            &.k-focus {
                @include fill(
                    $kendo-menu-popup-item-hover-text,
                    $kendo-menu-popup-item-hover-bg,
                    $kendo-menu-popup-item-hover-border,
                    $kendo-menu-popup-item-hover-gradient
                );
            }
        }
    }


    // Scrolling
    .k-menu-scroll-wrapper {
        .k-menu-scroll-button {
            .k-i-arrow-60-up::before {
                content: "\e013";
            }
            .k-i-arrow-60-right::before {
                content: "\e014";
            }
            .k-i-arrow-60-down::before {
                content: "\e015";
            }
            .k-i-arrow-60-left::before {
                content: "\e016";
            }
        }
    }
}
