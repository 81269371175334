@use "sass:math";
@use "../core/_index.scss" as *;

// Action sheet

$kendo-actionsheet-padding-x: null !default;
$kendo-actionsheet-padding-y: null !default;
$kendo-actionsheet-width: 360px !default;
$kendo-actionsheet-max-width: 100% !default;
$kendo-actionsheet-height: 60vh !default;
$kendo-actionsheet-max-height: 60vh !default;

$kendo-actionsheet-border-width: 0px !default;
$kendo-actionsheet-border-radius: 0px !default;

$kendo-actionsheet-font-size: var( --kendo-font-size, inherit )  !default;
$kendo-actionsheet-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-actionsheet-line-height: math.div( 20, 14 ) !default;

$kendo-actionsheet-bg: $kendo-component-bg !default;
$kendo-actionsheet-text: $kendo-component-text !default;
$kendo-actionsheet-border: $kendo-component-border !default;
$kendo-actionsheet-shadow: k-elevation(7) !default;


// Actionsheet header
$kendo-actionsheet-titlebar-padding-x: k-spacing(4) !default;
$kendo-actionsheet-titlebar-padding-y: k-spacing(2) !default;
$kendo-actionsheet-titlebar-border-width: 1px !default;
$kendo-actionsheet-titlebar-font-size: var( --kendo-font-size-lg, inherit ) !default;
$kendo-actionsheet-titlebar-font-family: null !default;
$kendo-actionsheet-titlebar-line-height: 1.25 !default;
$kendo-actionsheet-titlebar-gap: k-spacing(4) !default;

$kendo-actionsheet-titlebar-bg: null !default;
$kendo-actionsheet-titlebar-text: null !default;
$kendo-actionsheet-titlebar-border: $kendo-component-border !default;
$kendo-actionsheet-titlebar-gradient: null !default;
$kendo-actionsheet-titlebar-shadow: null !default;

$kendo-actionsheet-subtitle-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-actionsheet-subtitle-line-height: var( --kendo-line-height-sm, normal ) !default;
$kendo-actionsheet-subtitle-text: $kendo-subtle-text !default;

$kendo-actionsheet-footer-border-width: 1px !default;

$kendo-actionsheet-footer-bg: null !default;
$kendo-actionsheet-footer-text: null !default;
$kendo-actionsheet-footer-border: $kendo-component-border !default;
$kendo-actionsheet-footer-gradient: null !default;
$kendo-actionsheet-footer-shadow: null !default;

// Actionsheet item
$kendo-actionsheet-item-min-height: 40px !default;
$kendo-actionsheet-item-padding-x: k-spacing(4) !default;
$kendo-actionsheet-item-padding-y: k-spacing(2) !default;
$kendo-actionsheet-item-border-width: 1px !default;
$kendo-actionsheet-item-spacing: k-spacing(3) !default;

$kendo-actionsheet-item-title-font-weight: null !default;
$kendo-actionsheet-item-title-text-transform: null !default;

$kendo-actionsheet-item-description-font-size: .875em !default;
$kendo-actionsheet-item-description-text: $kendo-subtle-text !default;

$kendo-actionsheet-item-hover-bg: rgba( black, .04 ) !default;
$kendo-actionsheet-item-hover-text: null !default;
$kendo-actionsheet-item-hover-border: null !default;
$kendo-actionsheet-item-hover-gradient: null !default;
$kendo-actionsheet-item-hover-shadow: null !default;

$kendo-actionsheet-item-focus-bg: rgba( black, .12 ) !default;
$kendo-actionsheet-item-focus-text: null !default;
$kendo-actionsheet-item-focus-border: null !default;
$kendo-actionsheet-item-focus-gradient: null !default;
$kendo-actionsheet-item-focus-shadow: unset !default;

$kendo-actionsheet-item-disabled-bg: null !default;
$kendo-actionsheet-item-disabled-text: null !default;
$kendo-actionsheet-item-disabled-border: null !default;
$kendo-actionsheet-item-disabled-gradient: null !default;
$kendo-actionsheet-item-disabled-shadow: null !default;

$kendo-actionsheet-item-hover-background: rgba( black, .04 ) !default;
$kendo-actionsheet-item-focus-background: rgba( black, .12 ) !default;
$kendo-actionsheet-item-disabled-opacity: .42 !default;


// Adaptive Actionsheet
$kendo-adaptive-actionsheet-font-size: var( --kendo-font-size-lg, inherit ) !default;
$kendo-adaptive-actionsheet-titlebar-padding-y: k-spacing(4) !default;
$kendo-adaptive-actionsheet-titlebar-padding-x: $kendo-adaptive-actionsheet-titlebar-padding-y !default;

$kendo-adaptive-actionsheet-content-padding-y: k-spacing(2) !default;
$kendo-adaptive-actionsheet-content-padding-x: k-spacing(4) !default;

$kendo-adaptive-actionsheet-footer-padding-y: k-spacing(2) !default;
$kendo-adaptive-actionsheet-footer-padding-x: k-spacing(4) !default;

@forward "@progress/kendo-theme-core/scss/components/action-sheet/_variables.scss" with (
    $kendo-actionsheet-padding-x: $kendo-actionsheet-padding-x,
    $kendo-actionsheet-padding-y: $kendo-actionsheet-padding-y,
    $kendo-actionsheet-width: $kendo-actionsheet-width,
    $kendo-actionsheet-max-width: $kendo-actionsheet-max-width,
    $kendo-actionsheet-height: $kendo-actionsheet-height,
    $kendo-actionsheet-max-height: $kendo-actionsheet-max-height,
    $kendo-actionsheet-border-width: $kendo-actionsheet-border-width,
    $kendo-actionsheet-border-radius: $kendo-actionsheet-border-radius,
    $kendo-actionsheet-font-size: $kendo-actionsheet-font-size,
    $kendo-actionsheet-font-family: $kendo-actionsheet-font-family,
    $kendo-actionsheet-line-height: $kendo-actionsheet-line-height,
    $kendo-actionsheet-bg: $kendo-actionsheet-bg,
    $kendo-actionsheet-text: $kendo-actionsheet-text,
    $kendo-actionsheet-border: $kendo-actionsheet-border,
    $kendo-actionsheet-shadow: $kendo-actionsheet-shadow,
    $kendo-actionsheet-titlebar-padding-x: $kendo-actionsheet-titlebar-padding-x,
    $kendo-actionsheet-titlebar-padding-y: $kendo-actionsheet-titlebar-padding-y,
    $kendo-actionsheet-titlebar-border-width: $kendo-actionsheet-titlebar-border-width,
    $kendo-actionsheet-titlebar-font-size: $kendo-actionsheet-titlebar-font-size,
    $kendo-actionsheet-titlebar-font-family: $kendo-actionsheet-titlebar-font-family,
    $kendo-actionsheet-titlebar-line-height: $kendo-actionsheet-titlebar-line-height,
    $kendo-actionsheet-titlebar-gap: $kendo-actionsheet-titlebar-gap,
    $kendo-actionsheet-titlebar-bg: $kendo-actionsheet-titlebar-bg,
    $kendo-actionsheet-titlebar-text: $kendo-actionsheet-titlebar-text,
    $kendo-actionsheet-titlebar-border: $kendo-actionsheet-titlebar-border,
    $kendo-actionsheet-titlebar-gradient: $kendo-actionsheet-titlebar-gradient,
    $kendo-actionsheet-titlebar-shadow: $kendo-actionsheet-titlebar-shadow,
    $kendo-actionsheet-subtitle-font-size: $kendo-actionsheet-subtitle-font-size,
    $kendo-actionsheet-subtitle-line-height: $kendo-actionsheet-subtitle-line-height,
    $kendo-actionsheet-footer-border-width: $kendo-actionsheet-footer-border-width,
    $kendo-actionsheet-footer-bg: $kendo-actionsheet-footer-bg,
    $kendo-actionsheet-footer-text: $kendo-actionsheet-footer-text,
    $kendo-actionsheet-footer-border: $kendo-actionsheet-footer-border,
    $kendo-actionsheet-footer-gradient: $kendo-actionsheet-footer-gradient,
    $kendo-actionsheet-footer-shadow: $kendo-actionsheet-footer-shadow,
    $kendo-actionsheet-subtitle-text: $kendo-actionsheet-subtitle-text,
    $kendo-actionsheet-item-min-height: $kendo-actionsheet-item-min-height,
    $kendo-actionsheet-item-padding-x: $kendo-actionsheet-item-padding-x,
    $kendo-actionsheet-item-padding-y: $kendo-actionsheet-item-padding-y,
    $kendo-actionsheet-item-border-width: $kendo-actionsheet-item-border-width,
    $kendo-actionsheet-item-spacing: $kendo-actionsheet-item-spacing,
    $kendo-actionsheet-item-title-font-weight: $kendo-actionsheet-item-title-font-weight,
    $kendo-actionsheet-item-title-text-transform: $kendo-actionsheet-item-title-text-transform,
    $kendo-actionsheet-item-description-font-size: $kendo-actionsheet-item-description-font-size,
    $kendo-actionsheet-item-description-text: $kendo-actionsheet-item-description-text,
    $kendo-actionsheet-item-hover-bg: $kendo-actionsheet-item-hover-bg,
    $kendo-actionsheet-item-hover-text: $kendo-actionsheet-item-hover-text,
    $kendo-actionsheet-item-hover-border: $kendo-actionsheet-item-hover-border,
    $kendo-actionsheet-item-hover-gradient: $kendo-actionsheet-item-hover-gradient,
    $kendo-actionsheet-item-hover-shadow: $kendo-actionsheet-item-hover-shadow,
    $kendo-actionsheet-item-focus-bg: $kendo-actionsheet-item-focus-bg,
    $kendo-actionsheet-item-focus-text: $kendo-actionsheet-item-focus-text,
    $kendo-actionsheet-item-focus-border: $kendo-actionsheet-item-focus-border,
    $kendo-actionsheet-item-focus-gradient: $kendo-actionsheet-item-focus-gradient,
    $kendo-actionsheet-item-focus-shadow: $kendo-actionsheet-item-focus-shadow,
    $kendo-actionsheet-item-disabled-bg: $kendo-actionsheet-item-disabled-bg,
    $kendo-actionsheet-item-disabled-text: $kendo-actionsheet-item-disabled-text,
    $kendo-actionsheet-item-disabled-border: $kendo-actionsheet-item-disabled-border,
    $kendo-actionsheet-item-disabled-gradient: $kendo-actionsheet-item-disabled-gradient,
    $kendo-actionsheet-item-disabled-shadow: $kendo-actionsheet-item-disabled-shadow,
    $kendo-actionsheet-item-hover-background: $kendo-actionsheet-item-hover-background,
    $kendo-actionsheet-item-focus-background: $kendo-actionsheet-item-focus-background,
    $kendo-actionsheet-item-disabled-opacity: $kendo-actionsheet-item-disabled-opacity,
    $kendo-adaptive-actionsheet-font-size: $kendo-adaptive-actionsheet-font-size,
    $kendo-adaptive-actionsheet-titlebar-padding-y: $kendo-adaptive-actionsheet-titlebar-padding-y,
    $kendo-adaptive-actionsheet-titlebar-padding-x: $kendo-adaptive-actionsheet-titlebar-padding-x,
    $kendo-adaptive-actionsheet-content-padding-y: $kendo-adaptive-actionsheet-content-padding-y,
    $kendo-adaptive-actionsheet-content-padding-x: $kendo-adaptive-actionsheet-content-padding-x,
    $kendo-adaptive-actionsheet-footer-padding-y: $kendo-adaptive-actionsheet-footer-padding-y,
    $kendo-adaptive-actionsheet-footer-padding-x: $kendo-adaptive-actionsheet-footer-padding-x
);
