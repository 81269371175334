@use "../button/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/timeline/_layout.scss" as *;


@mixin kendo-timeline--layout() {

    @include kendo-timeline--layout-base();

    .k-timeline-horizontal .k-timeline-card {
        padding: 0 5px 5px;
    }

    .k-timeline-card .k-timeline-card-callout.k-callout-s {
        bottom: 5px;
    }

    .k-timeline-track-item:hover .k-timeline-circle::before,
    .k-timeline-track-item.k-hover .k-timeline-circle::before {
        opacity: $kendo-flat-button-hover-opacity;
    }

    .k-timeline-track-item.k-focus .k-timeline-circle::before {
        opacity: $kendo-flat-button-focus-opacity;
    }

}
