/**
 * ==============================================
 * Experimental: Gooey Effect
 * Dot Shuttle
 * ==============================================
 */

@use './variables';
@use './mixins';

$d: 3 * variables.$dot-spacing;
$dot-color-hsl: hsl(0deg, 100%, 0%);

.dot-shuttle {
  position: relative;
  left: - variables.$dot-spacing;

  @include mixins.dot(
    $width: 12px,
    $height: 12px,
    $radius: 6px,
    $bg-color: $dot-color-hsl,
    $color: transparent
  );

  margin: -1px 0;
  filter: blur(2px);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;

    @include mixins.dot(
      $width: 12px,
      $height: 12px,
      $radius: 6px,
      $bg-color: $dot-color-hsl,
      $color: transparent
    );

    filter: blur(2px);
  }

  &::before {
    left: variables.$dot-spacing;
    animation: dot-shuttle 2s infinite ease-out;
  }

  &::after {
    left: 2 * variables.$dot-spacing;
  }
}

@keyframes dot-shuttle {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(- $d);
  }

  75% {
    transform: translateX($d);
  }
}
