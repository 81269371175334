@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/radio/_theme.scss" as *;


@mixin kendo-radio--theme() {

    @include kendo-radio--theme-base();

    // Radio
    // Disabled state
    .k-radio:disabled + .k-radio-label {
        color: if($kendo-enable-color-system, k-color( subtle ), if( $kendo-is-dark-theme, $light-secondary-text, $dark-secondary-text ));
        opacity: 1;
        filter: none;
    }

}
