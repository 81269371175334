@use "@progress/kendo-theme-core/scss/components/floating-label/_layout.scss" as *;


@mixin kendo-floating-label--layout() {

    @include kendo-floating-label--layout-base();

    // Floating label
    .k-floating-label-container {
        > .k-floating-label {
            transform-origin: left bottom;
        }
    }

    [dir="rtl"] .k-floating-label-container,
    .k-rtl .k-floating-label-container,
    .k-floating-label-container[dir="rtl"] {
        > .k-floating-label {
            transform-origin: right bottom;
        }

    }

}
