@use "sass:math";
@use "./_functions.scss" as *;
@use "../core/_index.scss" as *;
@use "../core/color-system/_functions.scss" as *;

// Tooltip

/// The vertical padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-y: k-spacing(.5) !default;
/// The horizontal padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-x: calc( #{k-spacing(4)} / 2 ) !default;
/// The width of the border around the Tooltip.
/// @group tooltip
$kendo-tooltip-border-width: 0px !default;
/// The border radius of the Tooltip.
/// @group tooltip
$kendo-tooltip-border-radius: k-border-radius(md) !default;

/// The font family of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the Tooltip.
/// @group tooltip
$kendo-tooltip-line-height: math.div( 20, 14 ) !default;

/// The font size of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-font-size: calc( var( --kendo-font-size-sm, .75rem ) * 1.25 ) !default;
/// The line height of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-line-height: var( --kendo-line-height-xs, normal ) !default;

/// The size of the Tooltip callout.
/// @group tooltip
$kendo-tooltip-callout-size: 6px !default;

/// The default background of the Tooltip.
/// @group tooltip
$kendo-tooltip-bg: material-color( gray, 700, .9 ) !default;
/// The default text color of the Tooltip.
/// @group tooltip
$kendo-tooltip-text: get-base-contrast( gray, 700 ) !default;
/// The default border color of the Tooltip.
/// @group tooltip
$kendo-tooltip-border: $kendo-tooltip-bg !default;

/// The box-shadow of the Tooltip.
/// @group tooltip
$kendo-tooltip-shadow: k-elevation(2) !default;

/// The theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme-colors: $kendo-theme-colors !default;
/// The generated theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme: tooltip-theme( $kendo-tooltip-theme-colors ) !default;

@forward "@progress/kendo-theme-core/scss/components/tooltip/_variables.scss" with (
    $kendo-tooltip-padding-y: $kendo-tooltip-padding-y,
    $kendo-tooltip-padding-x: $kendo-tooltip-padding-x,
    $kendo-tooltip-border-width: $kendo-tooltip-border-width,
    $kendo-tooltip-border-radius: $kendo-tooltip-border-radius,
    $kendo-tooltip-font-family: $kendo-tooltip-font-family,
    $kendo-tooltip-font-size: $kendo-tooltip-font-size,
    $kendo-tooltip-line-height: $kendo-tooltip-line-height,
    $kendo-tooltip-title-font-size: $kendo-tooltip-title-font-size,
    $kendo-tooltip-title-line-height: $kendo-tooltip-title-line-height,
    $kendo-tooltip-callout-size: $kendo-tooltip-callout-size,
    $kendo-tooltip-bg: $kendo-tooltip-bg,
    $kendo-tooltip-text: $kendo-tooltip-text,
    $kendo-tooltip-border: $kendo-tooltip-border,
    $kendo-tooltip-shadow: $kendo-tooltip-shadow,
    $kendo-tooltip-theme-colors: $kendo-tooltip-theme-colors,
    $kendo-tooltip-theme: $kendo-tooltip-theme
);
