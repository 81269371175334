@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/progressbar/_layout.scss" as *;


@mixin kendo-progressbar--layout() {

    @include kendo-progressbar--layout-base();

    $kendo-progressbar-status-font-size: 11px !default;
    $kendo-progressbar-indeterminate-animation-timing: 2s infinite !default;

    .k-progressbar {
        @include border-radius( 0 );
        overflow: visible;

        .k-progress-status-wrap {
            font-size: $kendo-progressbar-status-font-size;
        }
    }

    .k-progressbar-horizontal {
        .k-progress-status-wrap {
            align-items: flex-start;
        }
        .k-progress-status {
            transform: translateY(-100%);
        }
    }

    .k-progressbar-vertical {
        .k-progress-status-wrap {
            align-items: flex-end;
        }
        .k-progress-status {
            transform: translateX(100%);
        }
    }

    .k-progressbar-indeterminate {
        overflow: hidden;

        &::before,
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transform-origin: 0 0;
        }

        &.k-progressbar-horizontal {
            &::before {
                top: 0;
                left: -150%;
                animation: kendo-progressbar-primary-indeterminate-horizontal $kendo-progressbar-indeterminate-animation-timing;
            }
            &::after {
                top: 0;
                left: -50%;
                animation: kendo-progressbar-secondary-indeterminate-horizontal $kendo-progressbar-indeterminate-animation-timing;
            }
        }

        &.k-progressbar-vertical {
            &::before {
                top: -150%;
                left: 0;
                animation: kendo-progressbar-primary-indeterminate-vertical $kendo-progressbar-indeterminate-animation-timing;
            }
            &::after {
                top: -50%;
                left: 0;
                animation: kendo-progressbar-secondary-indeterminate-vertical $kendo-progressbar-indeterminate-animation-timing;
            }
        }
    }
}
