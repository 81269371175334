/**
 * ==============================================
 * Experimental: Emoji
 * Dot Rolling
 * ==============================================
 */

@use './variables';
@use './mixins';

$d: 20px;

.dot-rolling {
  position: relative;
  height: variables.$dot-height;
  font-size: 10px;

  &::before {
    content: '⚽';
    display: inline-block;
    position: relative;
    transform: translateX(-25px);
    animation: dot-rolling 3s infinite;
  }
}

@keyframes dot-rolling {
  0% {
    content: '⚽';
    transform: translateX(-25px) rotateZ(0deg);
  }

  16.667% {
    content: '⚽';
    transform: translateX(25px) rotateZ(720deg);
  }

  33.333% {
    content: '⚽';
    transform: translateX(-25px) rotateZ(0deg);
  }

  34.333% {
    content: '🏀';
    transform: translateX(-25px) rotateZ(0deg);
  }

  50% {
    content: '🏀';
    transform: translateX(25px) rotateZ(720deg);
  }

  66.667% {
    content: '🏀';
    transform: translateX(-25px) rotateZ(0deg);
  }

  67.667% {
    content: '🏐';
    transform: translateX(-25px) rotateZ(0deg);
  }

  83.333% {
    content: '🏐';
    transform: translateX(25px) rotateZ(720deg);
  }

  100% {
    content: '🏐';
    transform: translateX(-25px) rotateZ(0deg);
  }
}
