@use "../grid/_variables.scss" as *;

// Treelist

/// The background color of the TreeList footer row.
/// @group treelist
$kendo-treelist-footer-row-bg: $kendo-grid-selected-bg !default;
/// The border width of the TreeList footer row.
/// @group treelist
$kendo-treelist-footer-row-border-width: 1px !default;

@forward "@progress/kendo-theme-core/scss/components/treelist/_variables.scss" with (
    $kendo-treelist-footer-row-bg: $kendo-treelist-footer-row-bg,
    $kendo-treelist-footer-row-border-width: $kendo-treelist-footer-row-border-width
);
