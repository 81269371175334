@use "./_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/progressbar/_theme.scss" as *;

@function _translate($direction, $value) { // stylelint-disable-line
    @return if($direction == horizontal, translateX($value), translateY($value));
}
@function _scale($direction, $value) { // stylelint-disable-line
    @return if($direction == horizontal, scaleX($value), scaleY($value));
}

@mixin kendo-progressbar-indeterminate-animation($direction) {
    $primary-animation-name: kendo-progressbar-primary-indeterminate-#{$direction};
    $secondary-animation-name: kendo-progressbar-secondary-indeterminate-#{$direction};

    @keyframes #{$primary-animation-name} {
        0% {
            transform: _translate($direction, 0) _scale($direction, 0);
        }
        20% {
            transform: _translate($direction, 0) _scale($direction, .08);
            animation-timing-function: cubic-bezier(.4, .08, .75, .8);
        }
        60% {
            transform: _translate($direction, 83%) _scale($direction, .75);
            animation-timing-function: cubic-bezier(.21, .25, .58, .98);
        }
        100% {
            transform: _translate($direction, 250%) _scale($direction, .08);
        }
    }

    @keyframes #{$secondary-animation-name} {
        0% {
            transform: _translate($direction, 0) _scale($direction, .08);
            animation-timing-function: cubic-bezier(.18, 0, .54, .42);
        }
        22% {
            transform: _translate($direction, 34%) _scale($direction, .45);
            animation-timing-function: cubic-bezier(.22, .25, .7, .9);
        }
        46% {
            transform: _translate($direction, 82%) _scale($direction, .75);
            animation-timing-function: cubic-bezier(.33, .3, .4, 1.2);
        }
        100% {
            transform: _translate($direction, 160%) _scale($direction, .08);
        }
    }
}

@mixin kendo-progressbar--theme() {

    @include kendo-progressbar--theme-base();

    @include kendo-progressbar-indeterminate-animation(horizontal);
    @include kendo-progressbar-indeterminate-animation(vertical);

    .k-progressbar-indeterminate {
        background-image: none;

        &::before,
        &::after {
            background: $kendo-progressbar-value-bg;
        }
    }

}
