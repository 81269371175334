@use "../core/_index.scss" as *;

// Switch

/// The font family of the Switch.
/// @group switch
$kendo-switch-font-family: null !default;

/// The border width of the Switch track.
/// @group switch
$kendo-switch-track-border-width: 0px !default;

/// The border width of the Switch thumb.
/// @group switch
$kendo-switch-thumb-border-width: 0px !default;

/// The text transform of the Switch label.
/// @group switch
$kendo-switch-label-text-transform: unset !default;

/// The display of the Switch label.
/// @group switch
$kendo-switch-label-display: none !default;

/// The map with the different Switch sizes.
/// @group switch
$kendo-switch-sizes: (
    sm: ( font-size: null, track-width: 26px, track-height:  12px, thumb-width: 16px, thumb-height: 16px, thumb-offset: -2px, label-offset: 0px ),
    md: ( font-size: null, track-width: 32px, track-height:  14px, thumb-width: 20px, thumb-height: 20px, thumb-offset: -3px, label-offset: 0px ),
    lg: ( font-size: null, track-width: 38px, track-height:  16px, thumb-width: 24px, thumb-height: 24px, thumb-offset: -4px, label-offset: 0px )
) !default;

/// The background of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 38%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .38 )) !default;
/// The text color of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-text: unset !default;
/// The border color of the track when the Switch is not checked
/// @group switch
$kendo-switch-off-track-border: unset !default;
/// The background gradient of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-gradient: null !default;

/// The background of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-bg: null !default;
/// The text color of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-text: null !default;
/// The border color of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-border: null !default;
/// The background gradient of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-gradient: null !default;

/// The background of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-bg: null !default;
/// The text color of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-text: null !default;
/// The border color of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-border: null !default;
/// The background gradient of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-gradient: null !default;
/// The ring around the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-ring: unset !default;

/// The background of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-bg: null !default;
/// The text color of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-text: null !default;
/// The border color of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-border: null !default;
/// The background gradient of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-gradient: null !default;

/// The background of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-bg: if($kendo-enable-color-system, k-color( app-surface ), if( $kendo-is-dark-theme, $kendo-color-black, $kendo-color-white )) !default;
/// The text color of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-text: unset !default;
/// The border color of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-border: unset !default;
/// The background gradient of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-gradient: null !default;

/// The background of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-gradient: null !default;


/// The background of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 44%, transparent), rgba( $kendo-color-primary, .54 )) !default;
/// The text color of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-text: unset !default;
/// The border color of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-border: unset !default;
/// The background gradient of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-gradient: null !default;

/// The background of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-bg: null !default;
/// The text color of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-text: null !default;
/// The border color of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-border: null !default;
/// The background gradient of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-gradient: null !default;

/// The background of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-bg: null !default;
/// The text color of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-text: null !default;
/// The border color of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-border: null !default;
/// The background gradient of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-gradient: null !default;
/// The ring around the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-ring: unset !default;

/// The background of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-bg: null !default;
/// The text color of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-text: null !default;
/// The border color of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-border: null !default;
/// The background gradient of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-gradient: null !default;

/// The background of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-bg: $kendo-color-primary !default;
/// The text color of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-text: unset !default;
/// The border color of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-border: unset !default;
/// The background gradient of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-gradient: null !default;

/// The background of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-gradient: null !default;

@forward "@progress/kendo-theme-core/scss/components/switch/_variables.scss" with (
    $kendo-switch-font-family: $kendo-switch-font-family,
    $kendo-switch-track-border-width: $kendo-switch-track-border-width,
    $kendo-switch-thumb-border-width: $kendo-switch-thumb-border-width,
    $kendo-switch-label-text-transform: $kendo-switch-label-text-transform,
    $kendo-switch-label-display: $kendo-switch-label-display,
    $kendo-switch-sizes: $kendo-switch-sizes,
    $kendo-switch-off-track-bg: $kendo-switch-off-track-bg,
    $kendo-switch-off-track-text: $kendo-switch-off-track-text,
    $kendo-switch-off-track-border: $kendo-switch-off-track-border,
    $kendo-switch-off-track-gradient: $kendo-switch-off-track-gradient,
    $kendo-switch-off-track-hover-bg: $kendo-switch-off-track-hover-bg,
    $kendo-switch-off-track-hover-text: $kendo-switch-off-track-hover-text,
    $kendo-switch-off-track-hover-border: $kendo-switch-off-track-hover-border,
    $kendo-switch-off-track-hover-gradient: $kendo-switch-off-track-hover-gradient,
    $kendo-switch-off-track-focus-bg: $kendo-switch-off-track-focus-bg,
    $kendo-switch-off-track-focus-text: $kendo-switch-off-track-focus-text,
    $kendo-switch-off-track-focus-border: $kendo-switch-off-track-focus-border,
    $kendo-switch-off-track-focus-gradient: $kendo-switch-off-track-focus-gradient,
    $kendo-switch-off-track-focus-ring: $kendo-switch-off-track-focus-ring,
    $kendo-switch-off-track-disabled-bg: $kendo-switch-off-track-disabled-bg,
    $kendo-switch-off-track-disabled-text: $kendo-switch-off-track-disabled-text,
    $kendo-switch-off-track-disabled-border: $kendo-switch-off-track-disabled-border,
    $kendo-switch-off-track-disabled-gradient: $kendo-switch-off-track-disabled-gradient,
    $kendo-switch-off-thumb-bg: $kendo-switch-off-thumb-bg,
    $kendo-switch-off-thumb-text: $kendo-switch-off-thumb-text,
    $kendo-switch-off-thumb-border: $kendo-switch-off-thumb-border,
    $kendo-switch-off-thumb-gradient: $kendo-switch-off-thumb-gradient,
    $kendo-switch-off-thumb-hover-bg: $kendo-switch-off-thumb-hover-bg,
    $kendo-switch-off-thumb-hover-text: $kendo-switch-off-thumb-hover-text,
    $kendo-switch-off-thumb-hover-border: $kendo-switch-off-thumb-hover-border,
    $kendo-switch-off-thumb-hover-gradient: $kendo-switch-off-thumb-hover-gradient,
    $kendo-switch-on-track-bg: $kendo-switch-on-track-bg,
    $kendo-switch-on-track-text: $kendo-switch-on-track-text,
    $kendo-switch-on-track-border: $kendo-switch-on-track-border,
    $kendo-switch-on-track-gradient: $kendo-switch-on-track-gradient,
    $kendo-switch-on-track-hover-bg: $kendo-switch-on-track-hover-bg,
    $kendo-switch-on-track-hover-text: $kendo-switch-on-track-hover-text,
    $kendo-switch-on-track-hover-border: $kendo-switch-on-track-hover-border,
    $kendo-switch-on-track-hover-gradient: $kendo-switch-on-track-hover-gradient,
    $kendo-switch-on-track-focus-bg: $kendo-switch-on-track-focus-bg,
    $kendo-switch-on-track-focus-text: $kendo-switch-on-track-focus-text,
    $kendo-switch-on-track-focus-border: $kendo-switch-on-track-focus-border,
    $kendo-switch-on-track-focus-gradient: $kendo-switch-on-track-focus-gradient,
    $kendo-switch-on-track-focus-ring: $kendo-switch-on-track-focus-ring,
    $kendo-switch-on-track-disabled-bg: $kendo-switch-on-track-disabled-bg,
    $kendo-switch-on-track-disabled-text: $kendo-switch-on-track-disabled-text,
    $kendo-switch-on-track-disabled-border: $kendo-switch-on-track-disabled-border,
    $kendo-switch-on-track-disabled-gradient: $kendo-switch-on-track-disabled-gradient,
    $kendo-switch-on-thumb-bg: $kendo-switch-on-thumb-bg,
    $kendo-switch-on-thumb-text: $kendo-switch-on-thumb-text,
    $kendo-switch-on-thumb-border: $kendo-switch-on-thumb-border,
    $kendo-switch-on-thumb-gradient: $kendo-switch-on-thumb-gradient,
    $kendo-switch-on-thumb-hover-bg: $kendo-switch-on-thumb-hover-bg,
    $kendo-switch-on-thumb-hover-text: $kendo-switch-on-thumb-hover-text,
    $kendo-switch-on-thumb-hover-border: $kendo-switch-on-thumb-hover-border,
    $kendo-switch-on-thumb-hover-gradient: $kendo-switch-on-thumb-hover-gradient
);
