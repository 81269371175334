@use "sass:map";
@use "sass:meta";
@use "./_functions.scss" as *;
@use "../functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/index.scss" as *;

// Adapted from https://github.com/angular/material2/blob/master/src/lib/core/theming/_palette.scss
// Adapted from https://github.com/angular/components/blob/master/src/material/core/theming/_theming.scss
$dark-primary-text: rgba(black, 0.87) !default;
$dark-secondary-text: rgba(black, 0.54) !default;
$dark-disabled-text: rgba(black, 0.38) !default;
$dark-dividers: rgba(black, 0.12) !default;
$light-primary-text: #ffffff !default;
$light-secondary-text: rgba(white, 0.7) !default;
$light-disabled-text: rgba(white, 0.5) !default;
$light-dividers: rgba(white, 0.12) !default;

$material-dark-complimentary: (
    is-dark: true,
    body-bg: #121212,
    body-text: $light-primary-text,
    subtle-text: $light-secondary-text,
    disabled-text: $light-disabled-text,
    component-bg: #121212,
    component-text: $light-primary-text,
    component-border: $light-dividers,
    base-bg: get-base-hue(gray, 900),
    hover-bg: rgba(white, 0.08),
    focus-bg: rgba(white, 0.24),
    elevation: #000000,
) !default;

$material-light-complimentary: (
    is-dark: false,
    body-bg: #ffffff,
    body-text: get-base-contrast(gray, 50),
    subtle-text: $dark-secondary-text,
    disabled-text: $dark-disabled-text,
    component-bg: #ffffff,
    component-text: $dark-primary-text,
    component-border: $dark-dividers,
    base-bg: #ffffff,
    hover-bg: rgba(black, 0.04),
    focus-bg: rgba(black, 0.12),
    elevation: #000000,
) !default;

$primary-palette-name: indigo !default;
$secondary-palette-name: pink !default;
$tertiary-palette-name: teal !default;
$adjust-contrast: true !default;
$theme-type: light !default; // light or dark

$primary-palette: material-palette($primary-palette-name, 500, 300, 700, $adjust-contrast, $theme-type) !default;
$secondary-palette: material-palette($secondary-palette-name, 500, 300, 700, $adjust-contrast, $theme-type) !default;
$tertiary-palette: material-palette($tertiary-palette-name, 500, 300, 700, $adjust-contrast, $theme-type) !default;

$warn: material-palette($base-palette: red, $theme-type: $theme-type);

$theme: material-theme($primary-palette, $secondary-palette, $theme-type, $material-dark-complimentary, $material-light-complimentary, $warn) !default;

// Root styles

// Color settings
$kendo-is-dark-theme: map.get($theme, is-dark) !default;

/// Color of shadows
$elevation: map.get($theme, elevation) !default;

// Primary colors
$kendo-color-primary: if($kendo-enable-color-system, k-color(primary), material-color($primary-palette, main)) !default;
$kendo-color-primary-lighter: material-color($primary-palette, lighter) !default;
$kendo-color-primary-darker: if($kendo-enable-color-system, k-color(primary-active), material-color($primary-palette, darker)) !default;
$kendo-color-primary-contrast: if($kendo-enable-color-system, k-color(on-primary), material-color($primary-palette, main-contrast)) !default;

// Secondary colors
$kendo-color-secondary: if($kendo-enable-color-system, k-color(secondary), material-color($secondary-palette, main)) !default;
$kendo-color-secondary-lighter: material-color($secondary-palette, lighter) !default;
$kendo-color-secondary-darker: material-color($secondary-palette, darker) !default;
$kendo-color-secondary-contrast: if($kendo-enable-color-system, k-color(on-secondary), material-color($secondary-palette, main-contrast)) !default;

/// Tertiary colors
$kendo-color-tertiary: if($kendo-enable-color-system, k-color(tertiary), material-color($tertiary-palette, main)) !default;
$kendo-color-tertiary-lighter: material-color($tertiary-palette, lighter) !default;
$kendo-color-tertiary-darker: material-color($tertiary-palette, darker) !default;
$kendo-color-tertiary-contrast: if($kendo-enable-color-system, k-color(on-tertiary), material-color($tertiary-palette, main-contrast)) !default;

// Info colors
$kendo-color-info: if($kendo-enable-color-system, k-color(info), #0058e9) !default;
$kendo-color-info-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-info, 2)) !default;
$kendo-color-info-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-info, 2)) !default;

// Success colors
$kendo-color-success: if($kendo-enable-color-system, k-color(success), #37b400) !default;
$kendo-color-success-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-success, 2)) !default;
$kendo-color-success-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-success, 2)) !default;

// Warning colors
$kendo-color-warning: if($kendo-enable-color-system, k-color(warning), #ffc000) !default;
$kendo-color-warning-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-warning, 2)) !default;
$kendo-color-warning-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-warning, 2)) !default;

// Error colors
$kendo-color-error: if($kendo-enable-color-system, k-color(error), #f31700) !default;
$kendo-color-error-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-error, 2)) !default;
$kendo-color-error-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-error, 2)) !default;

// Dark colors
$kendo-color-dark: if($kendo-enable-color-system, k-color(dark), get-base-hue(gray, 800)) !default;

// Light colors
$kendo-color-light: if($kendo-enable-color-system, k-color(light), get-base-hue(gray, 100)) !default;

// Inverse colors
$kendo-color-inverse: if($kendo-is-dark-theme, $kendo-color-light, $kendo-color-dark) !default;

// Body styles
$kendo-body-bg: if($kendo-enable-color-system, k-color(app-surface), map.get($theme, body-bg)) !default;
$kendo-body-text: if($kendo-enable-color-system, k-color(on-app-surface), map.get($theme, body-text)) !default;

$kendo-subtle-text: if($kendo-enable-color-system, k-color(subtle), map.get($theme, subtle-text)) !default;

$kendo-app-bg: if($kendo-enable-color-system, $kendo-body-bg, $kendo-body-bg) !default;
$kendo-app-text: if($kendo-enable-color-system, $kendo-body-text, $kendo-body-text) !default;
$kendo-app-border: if($kendo-enable-color-system, k-color(border), map.get($theme, component-border)) !default;

// Component styles
$kendo-component-bg: if($kendo-enable-color-system, k-color(surface-alt), map.get($theme, component-bg)) !default;
$kendo-component-text: if($kendo-enable-color-system, $kendo-body-text, map.get($theme, component-text)) !default;
$kendo-component-border: if($kendo-enable-color-system, $kendo-app-border, map.get($theme, component-border)) !default;

$kendo-base-bg: if($kendo-enable-color-system, $kendo-body-bg, map.get($theme, base-bg)) !default;
$kendo-base-text: if($kendo-enable-color-system, $kendo-body-text, map.get($theme, component-text)) !default;
$kendo-base-border: if($kendo-enable-color-system, $kendo-app-border, rgba(if($kendo-is-dark-theme, #ffffff, #000000), 0.08)) !default;
$kendo-base-gradient: if($kendo-enable-color-system, null, none) !default;

$kendo-component-header-bg: if($kendo-enable-color-system, $kendo-base-bg, $kendo-base-bg) !default;
$kendo-component-header-text: if($kendo-enable-color-system, $kendo-base-text, $kendo-base-text) !default;
$kendo-component-header-border: if($kendo-enable-color-system, $kendo-base-border, $kendo-base-border) !default;
$kendo-component-header-gradient: null !default;

// States styles
$kendo-hover-bg: if($kendo-enable-color-system, k-color( base-hover), map.get($theme, hover-bg)) !default;
$kendo-hover-text: if($kendo-enable-color-system, $kendo-base-text, $kendo-base-text) !default;
$kendo-hover-border: if($kendo-enable-color-system, $kendo-base-border, rgba($kendo-base-border, 0.15)) !default;
$kendo-hover-gradient: $kendo-base-gradient !default;

$kendo-selected-bg: if($kendo-enable-color-system, $kendo-color-secondary, $kendo-color-secondary) !default;
$kendo-selected-text: if($kendo-enable-color-system, $kendo-color-secondary-contrast, $kendo-color-secondary-contrast) !default;
$kendo-selected-border: if($kendo-enable-color-system, $kendo-base-border, rgba($kendo-base-border, 0.1)) !default;
$kendo-selected-gradient: $kendo-base-gradient !default;

$kendo-selected-hover-bg: if($kendo-enable-color-system, k-color( secondary-hover ), k-try-shade($kendo-selected-bg, 0.5)) !default;
$kendo-selected-hover-text: if($kendo-enable-color-system, $kendo-selected-text, $kendo-selected-text) !default;
$kendo-selected-hover-border: if($kendo-enable-color-system, $kendo-base-border, rgba($kendo-base-border, 0.1)) !default;
$kendo-selected-hover-gradient: $kendo-base-gradient !default;

// Link
$kendo-link-text: if($kendo-enable-color-system, $kendo-color-secondary, $kendo-color-secondary) !default;
$kendo-link-hover-text: if($kendo-enable-color-system, k-color(secondary-hover), $kendo-color-secondary-darker) !default;

// Validator
$kendo-invalid-bg: null !default;
$kendo-invalid-text: $kendo-color-error !default;
$kendo-invalid-border: $kendo-color-error !default;
$kendo-invalid-shadow: null !default;

$kendo-valid-bg: unset !default;
$kendo-valid-text: $kendo-color-success !default;
$kendo-valid-border: $kendo-color-success !default;
$kendo-valid-shadow: unset !default;

// Disabled Styling
$kendo-disabled-bg: null !default;
$kendo-disabled-text: if($kendo-enable-color-system, k-color( on-app-surface ), map.get($theme, disabled-text)) !default;
$kendo-disabled-border: null !default;
$kendo-disabled-opacity: 0.6 !default;
$kendo-disabled-filter: grayscale(0.1) !default;

$kendo-disabled-styling: (
    bg: $kendo-disabled-bg,
    color: $kendo-disabled-text,
    border: $kendo-disabled-border,
    opacity: $kendo-disabled-opacity,
    filter: $kendo-disabled-filter,
) !default;

$kendo-theme-colors: (
    "primary": $kendo-color-primary,
    "secondary": $kendo-color-secondary,
    "tertiary": $kendo-color-tertiary,
    "info": $kendo-color-info,
    "success": $kendo-color-success,
    "warning": $kendo-color-warning,
    "error": $kendo-color-error,
    "dark": $kendo-color-dark,
    "light": $kendo-color-light,
    "inverse": $kendo-color-inverse,
) !default;


@forward "@progress/kendo-theme-core/scss/color-system/_swatch-legacy.scss" with (
    $kendo-color-primary: $kendo-color-primary,
    $kendo-color-primary-lighter: $kendo-color-primary-lighter,
    $kendo-color-primary-darker: $kendo-color-primary-darker,
    $kendo-color-primary-contrast: $kendo-color-primary-contrast,

    $kendo-color-secondary: $kendo-color-secondary,
    $kendo-color-secondary-lighter: $kendo-color-secondary-lighter,
    $kendo-color-secondary-darker: $kendo-color-secondary-darker,
    $kendo-color-secondary-contrast: $kendo-color-secondary-contrast,

    $kendo-color-tertiary: $kendo-color-tertiary,
    $kendo-color-tertiary-lighter: $kendo-color-tertiary-lighter,
    $kendo-color-tertiary-darker: $kendo-color-tertiary-darker,
    $kendo-color-tertiary-contrast: $kendo-color-tertiary-contrast,

    $kendo-color-info: $kendo-color-info,
    $kendo-color-info-lighter: $kendo-color-info-lighter,
    $kendo-color-info-darker: $kendo-color-info-darker,

    $kendo-color-success: $kendo-color-success,
    $kendo-color-success-lighter: $kendo-color-success-lighter,
    $kendo-color-success-darker: $kendo-color-success-darker,

    $kendo-color-warning: $kendo-color-warning,
    $kendo-color-warning-lighter: $kendo-color-warning-lighter,
    $kendo-color-warning-darker: $kendo-color-warning-darker,

    $kendo-color-error: $kendo-color-error,
    $kendo-color-error-lighter: $kendo-color-error-lighter,
    $kendo-color-error-darker: $kendo-color-error-darker,

    $kendo-color-dark: $kendo-color-dark,

    $kendo-color-light: $kendo-color-light,

    $kendo-color-inverse: $kendo-color-inverse,

    $kendo-body-bg: $kendo-body-bg,
    $kendo-body-text: $kendo-body-text,

    $kendo-subtle-text: $kendo-subtle-text,

    $kendo-app-bg: $kendo-app-bg,
    $kendo-app-text: $kendo-app-text,
    $kendo-app-border: $kendo-app-border,

    $kendo-component-bg: $kendo-component-bg,
    $kendo-component-text: $kendo-component-text,
    $kendo-component-border: $kendo-component-border,

    $kendo-base-bg: $kendo-base-bg,
    $kendo-base-text: $kendo-base-text,
    $kendo-base-border: $kendo-base-border,
    $kendo-base-gradient: $kendo-base-gradient,

    $kendo-component-header-bg: $kendo-component-header-bg,
    $kendo-component-header-text: $kendo-component-header-text,
    $kendo-component-header-border: $kendo-component-header-border,
    $kendo-component-header-gradient: $kendo-component-header-gradient,

    $kendo-hover-bg: $kendo-hover-bg,
    $kendo-hover-text: $kendo-hover-text,
    $kendo-hover-border: $kendo-hover-border,
    $kendo-hover-gradient: $kendo-hover-gradient,

    $kendo-selected-bg: $kendo-selected-bg,
    $kendo-selected-text: $kendo-selected-text,
    $kendo-selected-border: $kendo-selected-border,
    $kendo-selected-gradient: $kendo-selected-gradient,

    $kendo-selected-hover-bg: $kendo-selected-hover-bg,
    $kendo-selected-hover-text: $kendo-selected-hover-text,
    $kendo-selected-hover-border: $kendo-selected-hover-border,
    $kendo-selected-hover-gradient: $kendo-selected-hover-gradient,

    $kendo-link-text: $kendo-link-text,
    $kendo-link-hover-text: $kendo-link-hover-text,

    $kendo-invalid-bg: $kendo-invalid-bg,
    $kendo-invalid-text: $kendo-invalid-text,
    $kendo-invalid-border: $kendo-invalid-border,
    $kendo-invalid-shadow: $kendo-invalid-shadow,

    $kendo-valid-bg: $kendo-valid-bg,
    $kendo-valid-text: $kendo-valid-text,
    $kendo-valid-border: $kendo-valid-border,
    $kendo-valid-shadow: $kendo-valid-shadow,

    $kendo-disabled-bg: $kendo-disabled-bg,
    $kendo-disabled-text: $kendo-disabled-text,
    $kendo-disabled-border: $kendo-disabled-border,
    $kendo-disabled-opacity: $kendo-disabled-opacity,
    $kendo-disabled-filter: $kendo-disabled-filter,

    $kendo-disabled-styling: $kendo-disabled-styling,
    $kendo-theme-colors: $kendo-theme-colors
);
