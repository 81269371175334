@use "../grid/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/gantt/_layout.scss" as *;


@mixin kendo-gantt--layout() {

    @include kendo-gantt--layout-base();

    // Timeline
    .k-rtl .k-gantt-timeline-pane {
        .k-header {
            border-width: 0 $kendo-grid-cell-vertical-border-width 1px 0;
        }
    }

}
