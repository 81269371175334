@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/spreadsheet/_theme.scss" as *;


@mixin kendo-spreadsheet--theme() {
    @include kendo-spreadsheet--theme-base();

    // Spreadsheet header
    .k-spreadsheet > .k-tabstrip-wrapper {

        .k-tabstrip-items-wrapper {
            color: $kendo-color-primary-contrast;
            background-color: $kendo-color-primary;
        }
        .k-tabstrip-items .k-item {
            color: inherit;
        }
        .k-tabstrip-items .k-item.k-active::after {
            border-color: $kendo-color-primary-contrast;
        }
    }

    // Quick access toolbar
    .k-spreadsheet-quick-access-toolbar {
        color: $kendo-color-primary-contrast;
        background-color: $kendo-color-primary;
    }

    // Selection
    .k-spreadsheet .k-selection-full,
    .k-spreadsheet .k-selection-partial {
        &::after { border-color: $kendo-color-secondary; }
    }

}
