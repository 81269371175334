@use "./_variables.scss" as *;
@use "../tooltip/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/forms/_layout.scss" as *;


@mixin kendo-form--layout() {

    @include kendo-form--layout-base();

    // Fields
    .k-edit-label {
        padding: 0;
        margin: 0;
        width: auto;
        font-size: var( --kendo-font-size-sm, inherit );
        line-height: 1;
        text-align: inherit;
        display: block;
        float: none;
    }
    .k-edit-field {
        width: auto;
        float: none;
        display: flex;
        flex-direction: row;

        > div {
            width: auto;
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
        }

        .k-upload {
            flex-wrap: wrap;
        }

        .k-validator-tooltip {
            bottom: 0;
            // TODO: do we need the tooltip variable?
            transform: translateY(100%) translateY($kendo-tooltip-callout-size);
        }
    }

}
