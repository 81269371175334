@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/grid/_layout.scss" as *;


@mixin kendo-grid--layout() {

    @include kendo-grid--layout-base();

    // Grid
    .k-grid {

        .k-group-footer td,
        .k-group-footer .k-table-td {
            border-top-width: 0;
        }

        .k-command-cell .k-button {
            @include box-shadow( none );
        }
    }

    // Grid header
    .k-grid-header {

        .k-table-th {
            font-weight: 700;

            .k-grid-filter {
                font-weight: normal;
            }
        }

        .k-grid-filter {
            border-radius: 50%;
        }

        .k-header-column-menu,
        .k-grid-header-menu  {
            border-radius: 50%;
        }

        .k-resizable-columns .k-table-th:not(.k-group-cell) {
            border-right-width: 1px;
            border-right-style: solid;
        }

        .k-resizable-columns .k-resize-handle .k-resize-handle-inner {
            width: 1px;
            height: 100%;
            cursor: col-resize;
        }
    }

    .k-cell-inner {
        .k-grid-filter,
        .k-header-column-menu,
        .k-grid-header-menu,
        .k-hierarchy-cell .k-icon {
            line-height: normal;
        }
    }

    .k-grouping-dropclue {
        width: 0;
        height: $kendo-grid-group-drop-hint-height;
        top: $kendo-grid-group-drop-hint-top;
        border-style: solid;
        border-width: 0 2px 0 0;

        &::before {
            border-width: 0;
        }
    }

    .k-resize-handle:hover .k-resize-handle-inner {
        width: 1px;
        height: 100%;
    }

    .k-column-resizer:hover::after {
        display: block;
        content: "";
        height: 100%;
        border-right: 2px solid currentColor;
    }

    .k-reorder-cue {
        margin-left: -1px;
        width: 2px;
    }

    .k-column-menu .k-menu:not(.k-context-menu) {

        .k-item {
            & > .k-link::before {
                content: none;
            }
        }

        &.k-menu-vertical {
            & > .k-item + .k-item {
                margin-top: 0;
            }
        }
    }

}
