@use "sass:math";
@use "../core/_index.scss" as *;
@use"../button/_variables.scss" as *;
@use"../typography/_variables.scss" as *;

// Card

/// The horizontal padding of the Card.
/// @group card
$kendo-card-padding-x: k-spacing(4) !default;
/// The vertical padding of the Card.
/// @group card
$kendo-card-padding-y: k-spacing(3) !default;
/// The width of the border around the Card.
/// @group card
$kendo-card-border-width: 0px !default;
/// The border radius of the Card.
/// @group card
$kendo-card-border-radius: k-border-radius(md) !default;
/// The inner border radius of the Card.
/// @group card
$kendo-card-inner-border-radius: calc( #{$kendo-card-border-radius} - #{$kendo-card-border-width} ) !default;
/// The font family of the Card.
/// @group card
$kendo-card-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Card.
/// @group card
$kendo-card-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the Card.
/// @group card
$kendo-card-line-height: math.div( 20, 14 ) !default;

/// The spacing between the Cards in the Card deck.
/// @group card
$kendo-card-deck-gap: k-spacing(4) !default;

/// The background color of the Card.
/// @group card
$kendo-card-bg: $kendo-component-bg !default;
/// The text color of the Card.
/// @group card
$kendo-card-text: $kendo-component-text !default;
/// The border color of the Card.
/// @group card
$kendo-card-border: $kendo-component-border !default;
/// The shadow of the Card.
/// @group card
$kendo-card-shadow: k-elevation(1) !default;
/// The background color of the focused Card.
/// @group card
$kendo-card-focus-bg: null !default;
/// The text color of the focused Card.
/// @group card
$kendo-card-focus-text: null !default;
/// The border color of the focused Card.
/// @group card
$kendo-card-focus-border: $kendo-component-border !default;

// TODO: double-check: the default theme is not supposed to elevate the Card on focus

/// The shadow of the focused Card.
/// @group card
$kendo-card-focus-shadow: k-elevation(3) !default;

/// The horizontal padding of the Card header.
/// @group card
$kendo-card-header-padding-x: $kendo-card-padding-x !default;
/// The vertical padding of the Card header.
/// @group card
$kendo-card-header-padding-y: $kendo-card-padding-y !default;
/// The bottom border width of the Card header.
/// @group card
$kendo-card-header-border-width: 1px !default;
/// The background color of the Card header.
/// @group card
$kendo-card-header-bg: null !default;
/// The text color of the Card header.
/// @group card
$kendo-card-header-text: unset !default;
/// The border color of the Card header.
/// @group card
$kendo-card-header-border: $kendo-card-border !default;

/// The horizontal padding of the Card body.
/// @group card
$kendo-card-body-padding-x: k-spacing(4) !default;
/// The vertical padding of the Card body.
/// @group card
$kendo-card-body-padding-y: k-spacing(4) !default;

/// The horizontal padding of the Card footer.
/// @group card
$kendo-card-footer-padding-x: $kendo-card-padding-x !default;
/// The vertical padding of the Card footer.
/// @group card
$kendo-card-footer-padding-y: $kendo-card-padding-y !default;
/// The top border width of the Card footer.
/// @group card
$kendo-card-footer-border-width: 1px !default;
/// The background color of the Card footer.
/// @group card
$kendo-card-footer-bg: null !default;
/// The text color of the Card footer.
/// @group card
$kendo-card-footer-text: unset !default;
/// The border color of the Card footer.
/// @group card
$kendo-card-footer-border: $kendo-card-border !default;

/// The bottom margin of the Card title.
/// @group card
$kendo-card-title-margin-bottom: k-spacing(3) !default;
/// The font size of the Card title.
/// @group card
$kendo-card-title-font-size: $kendo-h5-font-size !default;
/// The font family of the Card title.
/// @group card
$kendo-card-title-font-family: var( --kendo-font-family, inherit ) !default;
/// The line height of the Card title.
/// @group card
$kendo-card-title-line-height: 1.25 !default;
/// The font weight of the Card title.
/// @group card
$kendo-card-title-font-weight: 500 !default;
/// The letter spacing of the Card title.
/// @group card
$kendo-card-title-letter-spacing: null !default;

/// The bottom margin of the Card subtitle.
/// @group card
$kendo-card-subtitle-margin-bottom: k-spacing(3) !default;
/// The font size of the Card subtitle.
/// @group card
$kendo-card-subtitle-font-size: var( --kendo-font-size, inherit )  !default;
/// The font family of the Card subtitle.
/// @group card
$kendo-card-subtitle-font-family: var( --kendo-font-family, inherit ) !default;
/// The line height of the Card subtitle.
/// @group card
$kendo-card-subtitle-line-height: math.div( 20, 14 ) !default;
/// The font weight of the Card subtitle.
/// @group card
$kendo-card-subtitle-font-weight: 500 !default;
/// The letter spacing of the Card subtitle.
/// @group card
$kendo-card-subtitle-letter-spacing: null !default;
/// The text color of the Card subtitle.
/// @group card
$kendo-card-subtitle-text: $kendo-subtle-text !default;

/// The maximum width of the Card image.
/// @group card
$kendo-card-img-max-width: 100px !default;
/// The size of the Avatar in the Card.
/// @group card
$kendo-card-avatar-size: 45px !default;
/// The spacing between the Avatar and the text in the Card.
/// @group card
$kendo-card-avatar-spacing: $kendo-card-header-padding-x !default;

/// The horizontal padding of the Card actions.
/// @group card
$kendo-card-actions-padding-x: k-spacing(2) !default;
/// The vertical padding of the Card actions.
/// @group card
$kendo-card-actions-padding-y: k-spacing(2) !default;
/// The top border width of the Card actions.
/// @group card
$kendo-card-actions-border-width: 1px !default;
/// The spacing between the Card actions.
/// @group card
$kendo-card-actions-gap: k-spacing(2) !default;

/// The border radius of the scroll button in the Card deck.
/// @group card
$kendo-card-deck-scroll-button-radius: 50% !default;
/// The border radius of the scroll button in the Card deck.
/// @group card
$kendo-card-deck-scroll-button-offset: calc( #{$kendo-card-deck-gap} / 2 ) !default;

/// The width of the Card callout.
/// @group card
$kendo-card-callout-width: 20px !default;
/// The height of the Card callout.
/// @group card
$kendo-card-callout-height: 20px !default;

@forward "@progress/kendo-theme-core/scss/components/card/_variables.scss" with (
    $kendo-card-padding-x: $kendo-card-padding-x,
    $kendo-card-padding-y: $kendo-card-padding-y,
    $kendo-card-border-width: $kendo-card-border-width,
    $kendo-card-border-radius: $kendo-card-border-radius,
    $kendo-card-inner-border-radius: $kendo-card-inner-border-radius,
    $kendo-card-font-family: $kendo-card-font-family,
    $kendo-card-font-size: $kendo-card-font-size,
    $kendo-card-line-height: $kendo-card-line-height,
    $kendo-card-deck-gap: $kendo-card-deck-gap,
    $kendo-card-bg: $kendo-card-bg,
    $kendo-card-text: $kendo-card-text,
    $kendo-card-border: $kendo-card-border,
    $kendo-card-shadow: $kendo-card-shadow,
    $kendo-card-focus-bg: $kendo-card-focus-bg,
    $kendo-card-focus-text: $kendo-card-focus-text,
    $kendo-card-focus-border: $kendo-card-focus-border,
    $kendo-card-focus-shadow: $kendo-card-focus-shadow,
    $kendo-card-header-padding-x: $kendo-card-header-padding-x,
    $kendo-card-header-padding-y: $kendo-card-header-padding-y,
    $kendo-card-header-border-width: $kendo-card-header-border-width,
    $kendo-card-header-bg: $kendo-card-header-bg,
    $kendo-card-header-text: $kendo-card-header-text,
    $kendo-card-header-border: $kendo-card-header-border,
    $kendo-card-body-padding-x: $kendo-card-body-padding-x,
    $kendo-card-body-padding-y: $kendo-card-body-padding-y,
    $kendo-card-footer-padding-x: $kendo-card-footer-padding-x,
    $kendo-card-footer-padding-y: $kendo-card-footer-padding-y,
    $kendo-card-footer-border-width: $kendo-card-footer-border-width,
    $kendo-card-footer-bg: $kendo-card-footer-bg,
    $kendo-card-footer-text: $kendo-card-footer-text,
    $kendo-card-footer-border: $kendo-card-footer-border,
    $kendo-card-title-margin-bottom: $kendo-card-title-margin-bottom,
    $kendo-card-title-font-size: $kendo-card-title-font-size,
    $kendo-card-title-font-family: $kendo-card-title-font-family,
    $kendo-card-title-line-height: $kendo-card-title-line-height,
    $kendo-card-title-font-weight: $kendo-card-title-font-weight,
    $kendo-card-title-letter-spacing: $kendo-card-title-letter-spacing,
    $kendo-card-subtitle-margin-bottom: $kendo-card-subtitle-margin-bottom,
    $kendo-card-subtitle-font-size: $kendo-card-subtitle-font-size,
    $kendo-card-subtitle-font-family: $kendo-card-subtitle-font-family,
    $kendo-card-subtitle-line-height: $kendo-card-subtitle-line-height,
    $kendo-card-subtitle-font-weight: $kendo-card-subtitle-font-weight,
    $kendo-card-subtitle-letter-spacing: $kendo-card-subtitle-letter-spacing,
    $kendo-card-subtitle-text: $kendo-card-subtitle-text,
    $kendo-card-img-max-width: $kendo-card-img-max-width,
    $kendo-card-avatar-size: $kendo-card-avatar-size,
    $kendo-card-avatar-spacing: $kendo-card-avatar-spacing,
    $kendo-card-actions-padding-x: $kendo-card-actions-padding-x,
    $kendo-card-actions-padding-y: $kendo-card-actions-padding-y,
    $kendo-card-actions-border-width: $kendo-card-actions-border-width,
    $kendo-card-actions-gap: $kendo-card-actions-gap,
    $kendo-card-deck-scroll-button-radius: $kendo-card-deck-scroll-button-radius,
    $kendo-card-deck-scroll-button-offset: $kendo-card-deck-scroll-button-offset,
    $kendo-card-callout-width: $kendo-card-callout-width,
    $kendo-card-callout-height: $kendo-card-callout-height
);
