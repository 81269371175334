@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/components/calendar/_theme.scss" as *;


@mixin kendo-calendar--theme() {

    @include kendo-calendar--theme-base();

    // Calendar
    .k-calendar {

        .k-focus .k-link {
            @include fill(
                $kendo-calendar-cell-hover-text,
                $kendo-calendar-cell-hover-bg,
                $kendo-calendar-cell-hover-border
            );
        }
        .k-selected.k-focus .k-link {
            @include fill(
                $kendo-calendar-cell-selected-text,
                if($kendo-enable-color-system, $kendo-calendar-cell-selected-bg, k-try-tint($kendo-calendar-cell-selected-bg)),
                $kendo-calendar-cell-selected-border
            );
        }

    }

}
