@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/components/listview/_theme.scss" as *;


@mixin kendo-listview--theme() {

    @include kendo-listview--theme-base();

    // Listview
    .k-listview {}


    // Listview content
    .k-listview-content {}
    .k-listview-content > .k-selected.k-focus {
        background-color: if($kendo-enable-color-system, k-color( base-active ), rgba( k-contrast-color( $kendo-listview-bg ), .12 ));
    }

}
