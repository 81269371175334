@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/components/upload/_theme.scss" as *;


@mixin kendo-upload--theme() {

    @include kendo-upload--theme-base();

    // Upload
    .k-upload {
        .k-upload-button {
            @include box-shadow( none );
            background-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-upload-dropzone-bg ));
        }
    }

}
