@use "../core/_index.scss" as *;
@use"../input/_variables.scss" as *;
@use"../tooltip/_variables.scss" as *;
@use"../typography/_variables.scss" as *;

// Forms

/// The padding of the inline Form.
/// @group form
$kendo-form-spacer: calc( #{k-spacing(4)} * 2 ) !default;

/// The font size of the Form.
/// @group form
$kendo-form-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Form.
/// @group form
$kendo-form-line-height: var( --kendo-line-height, normal ) !default;
/// The line height of the Form in em units.
/// @group form
$kendo-form-line-height-em: calc( #{$kendo-form-line-height} * 1em ) !default;
/// The line height of the small Form.
/// @group form
$kendo-form-sm-line-height: $kendo-line-height-sm !default;
/// The line height of the large Form.
/// @group form
$kendo-form-lg-line-height: $kendo-line-height-lg !default;

/// The margin of the Form fieldset.
/// @group form
$kendo-form-fieldset-margin: 2em 0 0 !default;
/// The padding of the Form fieldset.
/// @group form
$kendo-form-fieldset-padding: k-spacing(0) !default;

/// The margin of the Form legend.
/// @group form
$kendo-form-legend-margin: 0 0 1em !default;
/// The padding of the Form legend.
/// @group form
$kendo-form-legend-padding: k-spacing(0) !default;
/// The border width of the Form legend.
/// @group form
$kendo-form-legend-border-width: 0 0 2px !default;
/// The border style of the Form legend.
/// @group form
$kendo-form-legend-border-style: solid !default;
/// The border color of the Form legend.
/// @group form
$kendo-form-legend-border-color: $kendo-component-border !default;
/// The width of the Form legend.
/// @group form
$kendo-form-legend-width: 100% !default;
/// The font size of the Form legend.
/// @group form
$kendo-form-legend-font-size: var( --kendo-font-size, inherit ) !default;
/// The text capitalization of the Form legend.
/// @group form
$kendo-form-legend-text-transform: uppercase !default;

/// The bottom margin of the Form label.
/// @group form
$kendo-form-label-margin-bottom: k-spacing(0) !default;

/// The horizontal margin of the Form buttons.
/// @group form
$kendo-form-button-margin-x: k-spacing(2) !default;

/// The font size of the Form hint.
/// @group form
$kendo-form-hint-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font style of the Form hint.
/// @group form
$kendo-form-hint-font-style: normal !default;
/// The top margin of the Form hint.
/// @group form
$kendo-form-hint-margin-top: k-spacing(1) !default;

/// The row spacing of the small Form.
/// @group form
$kendo-form-sm-rows-spacing: k-spacing(4) !default;
/// The row spacing of the medium Form.
/// @group form
$kendo-form-md-rows-spacing: k-spacing(6) !default;
/// The row spacing of the large Form.
/// @group form
$kendo-form-lg-rows-spacing: k-spacing(7) !default;

/// The margin of the Form separator.
/// @group form
$kendo-form-separator-margin: $kendo-form-md-rows-spacing 0 0 !default;
/// The border color of the Form separator.
/// @group form
$kendo-form-separator-border-color: $kendo-form-legend-border-color !default;

/// The top padding of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-padding-top: calc( #{k-spacing(2.5)} / 2 ) !default;
/// The horizontal margin of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-margin-x: k-spacing(2.5) !default;
/// The width of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-width: 25% !default;
/// The horizontal alignment of the label in the horizontal Form.
/// @group form
$kendo-horizontal-form-label-align: flex-end !default;

/// The maximum width of the field wrap in the horizontal Form.
/// @group form
$kendo-horizontal-form-field-wrap-max-width: calc( ( 100% - #{$kendo-horizontal-form-label-width} ) - #{$kendo-horizontal-form-label-margin-x} ) !default;

/// The width of the inline Form element.
/// @group form
$kendo-inline-form-element-width: 25% !default;

/// The padding of the inline Form field.
/// @group form
$kendo-inline-form-field-padding-x: calc( #{k-spacing(1)} + #{$kendo-input-border-width} ) !default;
/// The padding of the block Form field.
/// @group form
$kendo-inline-form-field-padding-y: 0 k-spacing(6) !default;

/// The invalid text color of the Form.
/// @group form
$kendo-forms-invalid-color: inherit !default;


/// The horizontal margin of the optional label in the Form.
/// @group form
$kendo-label-optional-margin-x: k-spacing(1.5) !default;
/// The font size of the optional label in the Form.
/// @group form
$kendo-label-optional-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font style of the optional label in the Form.
/// @group form
$kendo-label-optional-font-style: italic !default;


/// The margin of the Form fieldset.
/// @group form
$kendo-fieldset-margin: k-spacing(7.5) !default;
/// The font size of the Form fieldset.
/// @group form
$kendo-fieldset-font-size: $kendo-h4-font-size !default;
/// The background color of the Form fieldset.
/// @group form
$kendo-fieldset-bg: null !default;
/// The text color of the Form fieldset.
/// @group form
$kendo-fieldset-text: null !default;
/// The border color of the Form fieldset.
/// @group form
$kendo-fieldset-border: null !default;

/// The padding of the Form fieldset legend.
/// @group form
$kendo-fieldset-legend-padding: 0 k-spacing(4) 0 0 !default;
/// The margin of the Form fieldset legend
/// @group form
$kendo-fieldset-legend-margin: calc( #{k-spacing(1.5)} * 2 ) !default;

/// The margin of the Form alert error.
/// @group form
$kendo-form-alert-error-margin: calc( #{k-spacing(1)} * 2 ) !default;

/// The margin of the Form field info.
/// @group form
$kendo-form-field-info-margin: k-spacing(4) !default;

/// The background color of the Form legend.
/// @group form
$kendo-fieldset-legend-bg: null !default;
/// The text color of the Form legend.
/// @group form
$kendo-fieldset-legend-text: unset !default;
/// The border color of the Form legend.
/// @group form
$kendo-fieldset-legend-border: null !default;

/// The sizes map for the Form.
/// @group form
$kendo-form-sizes: (
    sm: (
        form-rows-spacing: $kendo-form-sm-rows-spacing
    ),
    md: (
        form-rows-spacing: $kendo-form-md-rows-spacing
    ),
    lg: (
        form-rows-spacing: $kendo-form-lg-rows-spacing
    )
) !default;

@forward "@progress/kendo-theme-core/scss/components/forms/_variables.scss" with (
    $kendo-form-spacer: $kendo-form-spacer,
    $kendo-form-font-size: $kendo-form-font-size,
    $kendo-form-line-height: $kendo-form-line-height,
    $kendo-form-line-height-em: $kendo-form-line-height-em,
    $kendo-form-sm-line-height: $kendo-form-sm-line-height,
    $kendo-form-lg-line-height: $kendo-form-lg-line-height,
    $kendo-form-fieldset-margin: $kendo-form-fieldset-margin,
    $kendo-form-fieldset-padding: $kendo-form-fieldset-padding,
    $kendo-form-legend-margin: $kendo-form-legend-margin,
    $kendo-form-legend-padding: $kendo-form-legend-padding,
    $kendo-form-legend-border-width: $kendo-form-legend-border-width,
    $kendo-form-legend-border-style: $kendo-form-legend-border-style,
    $kendo-form-legend-border-color: $kendo-form-legend-border-color,
    $kendo-form-legend-width: $kendo-form-legend-width,
    $kendo-form-legend-font-size: $kendo-form-legend-font-size,
    $kendo-form-legend-text-transform: $kendo-form-legend-text-transform,
    $kendo-form-label-margin-bottom: $kendo-form-label-margin-bottom,
    $kendo-form-button-margin-x: $kendo-form-button-margin-x,
    $kendo-form-hint-font-size: $kendo-form-hint-font-size,
    $kendo-form-hint-font-style: $kendo-form-hint-font-style,
    $kendo-form-hint-margin-top: $kendo-form-hint-margin-top,
    $kendo-form-sm-rows-spacing: $kendo-form-sm-rows-spacing,
    $kendo-form-md-rows-spacing: $kendo-form-md-rows-spacing,
    $kendo-form-lg-rows-spacing: $kendo-form-lg-rows-spacing,
    $kendo-form-separator-margin: $kendo-form-separator-margin,
    $kendo-form-separator-border-color: $kendo-form-separator-border-color,
    $kendo-horizontal-form-label-padding-top: $kendo-horizontal-form-label-padding-top,
    $kendo-horizontal-form-label-margin-x: $kendo-horizontal-form-label-margin-x,
    $kendo-horizontal-form-label-width: $kendo-horizontal-form-label-width,
    $kendo-horizontal-form-label-align: $kendo-horizontal-form-label-align,
    $kendo-horizontal-form-field-wrap-max-width: $kendo-horizontal-form-field-wrap-max-width,
    $kendo-inline-form-element-width: $kendo-inline-form-element-width,
    $kendo-forms-invalid-color: $kendo-forms-invalid-color,
    $kendo-label-optional-margin-x: $kendo-label-optional-margin-x,
    $kendo-label-optional-font-size: $kendo-label-optional-font-size,
    $kendo-label-optional-font-style: $kendo-label-optional-font-style,
    $kendo-fieldset-margin: $kendo-fieldset-margin,
    $kendo-fieldset-font-size: $kendo-fieldset-font-size,
    $kendo-fieldset-bg: $kendo-fieldset-bg,
    $kendo-fieldset-text: $kendo-fieldset-text,
    $kendo-fieldset-border: $kendo-fieldset-border,
    $kendo-fieldset-legend-bg: $kendo-fieldset-legend-bg,
    $kendo-fieldset-legend-text: $kendo-fieldset-legend-text,
    $kendo-fieldset-legend-border: $kendo-fieldset-legend-border,
    $kendo-form-sizes: $kendo-form-sizes,
    $kendo-inline-form-field-padding-x: $kendo-inline-form-field-padding-x,
    $kendo-inline-form-field-padding-y: $kendo-inline-form-field-padding-y,
    $kendo-fieldset-legend-padding: $kendo-fieldset-legend-padding,
    $kendo-fieldset-legend-margin: $kendo-fieldset-legend-margin,
    $kendo-form-alert-error-margin: $kendo-form-alert-error-margin,
    $kendo-form-field-info-margin: $kendo-form-field-info-margin
);
