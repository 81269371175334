@use "sass:math";
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Drag hint
$kendo-drag-hint-padding-x: k-spacing(4) !default;
$kendo-drag-hint-padding-y: calc( #{k-spacing(4)} / 2 ) !default;
$kendo-drag-hint-border-width: 0px !default;
$kendo-drag-hint-border-radius: 9999px !default;
$kendo-drag-hint-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-drag-hint-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-drag-hint-line-height: math.div( 20, 14 ) !default;

$kendo-drag-hint-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-body-bg )) !default;
$kendo-drag-hint-text: $kendo-body-text !default;
$kendo-drag-hint-border: unset !default;
$kendo-drag-hint-gradient: null !default;
$kendo-drag-hint-shadow: k-elevation(3) !default;

$kendo-drag-hint-opacity: null !default;


// Drop hint
$kendo-drop-hint-arrow-size: 8px !default;
$kendo-drop-hint-arrow-spacing: math.div( $kendo-drop-hint-arrow-size, 2 ) !default;
$kendo-drop-hint-line-h-width: 20px !default;
$kendo-drop-hint-line-h-height: 1px !default;
$kendo-drop-hint-line-v-width: $kendo-drop-hint-line-h-height !default;
$kendo-drop-hint-line-v-height: $kendo-drop-hint-line-h-width !default;

$kendo-drop-hint-bg: $kendo-color-primary !default;

@forward "@progress/kendo-theme-core/scss/components/draggable/_variables.scss" with (
    $kendo-drag-hint-padding-x: $kendo-drag-hint-padding-x,
    $kendo-drag-hint-padding-y: $kendo-drag-hint-padding-y,
    $kendo-drag-hint-border-width: $kendo-drag-hint-border-width,
    $kendo-drag-hint-border-radius: $kendo-drag-hint-border-radius,
    $kendo-drag-hint-font-size: $kendo-drag-hint-font-size,
    $kendo-drag-hint-font-family: $kendo-drag-hint-font-family,
    $kendo-drag-hint-line-height: $kendo-drag-hint-line-height,
    $kendo-drag-hint-bg: $kendo-drag-hint-bg,
    $kendo-drag-hint-text: $kendo-drag-hint-text,
    $kendo-drag-hint-border: $kendo-drag-hint-border,
    $kendo-drag-hint-gradient: $kendo-drag-hint-gradient,
    $kendo-drag-hint-shadow: $kendo-drag-hint-shadow,
    $kendo-drag-hint-opacity: $kendo-drag-hint-opacity,
    $kendo-drop-hint-arrow-size: $kendo-drop-hint-arrow-size,
    $kendo-drop-hint-arrow-spacing: $kendo-drop-hint-arrow-spacing,
    $kendo-drop-hint-line-h-width: $kendo-drop-hint-line-h-width,
    $kendo-drop-hint-line-h-height: $kendo-drop-hint-line-h-height,
    $kendo-drop-hint-line-v-width: $kendo-drop-hint-line-v-width,
    $kendo-drop-hint-line-v-height: $kendo-drop-hint-line-v-height,
    $kendo-drop-hint-bg: $kendo-drop-hint-bg
);
