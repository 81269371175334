@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/checkbox/_theme.scss" as *;


@mixin kendo-checkbox--theme() {

    @include kendo-checkbox--theme-base();

    // Checkbox
    // Disabled state
    .k-checkbox:disabled + .k-checkbox-label {
        color: if($kendo-enable-color-system, k-color( subtle ), if( $kendo-is-dark-theme, $light-secondary-text, $dark-secondary-text ));
        opacity: 1;
        filter: none;
    }

}
