@use "@progress/kendo-theme-core/scss/components/switch/_layout.scss" as *;


@mixin kendo-switch--layout() {

    @include kendo-switch--layout-base();

    // Switch
    .k-switch {

        // Thumb ripple
        .k-switch-thumb::before {
            content: "";
            width: 200%;
            height: 200%;
            border-radius: 100%;
            display: none;
            opacity: 0;
            transform: translate( -50%, -50% );
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            pointer-events: none;
        }

        // Hover
        &:hover,
        &.k-hover {
            .k-switch-thumb::before {
                display: block;
                opacity: .04;
            }
        }

        // Focus
        &:focus,
        &.k-focus {
            .k-switch-thumb::before {
                display: block;
                opacity: .12;
            }
        }
    }
}
