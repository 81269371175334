@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/dialog/_theme.scss" as *;


@mixin kendo-dialog--theme() {

    @include kendo-dialog--theme-base();

    // Dialog
    .k-dialog {}


    // Titlebar
    .k-dialog-titlebar {
        @include fill(
            $kendo-dialog-titlebar-text,
            $kendo-dialog-titlebar-bg,
            $kendo-dialog-titlebar-border
        );
    }

    // Content scroll border
    .k-dialog-content.k-scroll {
        border-color: $kendo-component-border;
    }

}
