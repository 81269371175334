@use "sass:math";
@use "../core/_index.scss" as *;
@use "./_functions.scss" as *;
@use "../popup/_variables.scss" as *;
@use "../icons/_variables.scss" as *;

// Notification

/// The row-gap between the elements in the Notification group.
/// @group notification
$kendo-notification-group-gap: k-spacing(2) !default;

/// The horizontal padding of the Notification.
/// @group notification
$kendo-notification-padding-x: k-spacing(4) !default;
/// The vertical padding of the Notification.
/// @group notification
$kendo-notification-padding-y: k-spacing(3.5) !default;
/// The width of the border around the Notification.
/// @group notification
$kendo-notification-border-width: 0px !default;
/// The border radius of the Notification.
/// @group notification
$kendo-notification-border-radius: k-spacing(1) !default;
/// The font family of the Notification.
/// @group notification
$kendo-notification-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Notification.
/// @group notification
$kendo-notification-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Notification.
/// @group notification
$kendo-notification-line-height: math.div( 20, 14 ) !default;
/// The background color of the Notification.
/// @group notification
$kendo-notification-bg: $kendo-component-bg !default;
/// The text color of the Notification.
/// @group notification
$kendo-notification-text: $kendo-component-text !default;
/// The border color of the Notification.
/// @group notification
$kendo-notification-border: $kendo-component-border !default;
/// The box shadow of the Notification.
/// @group notification
$kendo-notification-shadow: $kendo-popup-shadow !default;

/// The horizontal spacing of the Notification icon.
/// @group notification
$kendo-notification-icon-spacing: $kendo-icon-spacing !default;

/// The theme colors map for the Notification.
/// @group notification
$kendo-notification-theme-colors: $kendo-theme-colors !default;
/// The generated theme colors map for the Notification.
/// @group notification
$kendo-notification-theme: notification-theme( $kendo-notification-theme-colors ) !default;

@forward "@progress/kendo-theme-core/scss/components/notification/_variables.scss" with (
    $kendo-notification-group-gap: $kendo-notification-group-gap,
    $kendo-notification-padding-x: $kendo-notification-padding-x,
    $kendo-notification-padding-y: $kendo-notification-padding-y,
    $kendo-notification-border-width: $kendo-notification-border-width,
    $kendo-notification-border-radius: $kendo-notification-border-radius,
    $kendo-notification-font-family: $kendo-notification-font-family,
    $kendo-notification-font-size: $kendo-notification-font-size,
    $kendo-notification-line-height: $kendo-notification-line-height,
    $kendo-notification-bg: $kendo-notification-bg,
    $kendo-notification-text: $kendo-notification-text,
    $kendo-notification-border: $kendo-notification-border,
    $kendo-notification-shadow: $kendo-notification-shadow,
    $kendo-notification-icon-spacing: $kendo-notification-icon-spacing,
    $kendo-notification-theme-colors: $kendo-notification-theme-colors,
    $kendo-notification-theme: $kendo-notification-theme
);
