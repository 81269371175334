@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/components/list/_theme.scss" as *;


@mixin kendo-list--theme() {
    @include kendo-list--theme-base();

    // List item
    .k-list-item {
        &.k-selected:hover,
        &.k-selected.k-hover {
            background-color: if($kendo-enable-color-system, k-color( base-active ), k-color-shade( $kendo-list-item-selected-bg ));
        }
    }
}
