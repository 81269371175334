/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */

@use './variables';
@use './mixins';

$spacing: variables.$dot-width + 6px;
$top-pos: $spacing * .5;
$left-pos: -9999px;
$x1: - $left-pos - $top-pos;
$x2: - $left-pos - $top-pos + $spacing;
$y1: 0;
$y2: - $spacing;

.dot-bricks {
  position: relative;
  top: $top-pos;
  left: $left-pos;

  @include mixins.dot;

  box-shadow:
    $x1 $y2 0 0 variables.$dot-before-color,
    $x1 $y1 0 0 variables.$dot-color,
    $x2 $y1 0 0 variables.$dot-after-color;
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow:
      $x1 $y2 0 0 variables.$dot-before-color,
      $x1 $y1 0 0 variables.$dot-color,
      $x2 $y1 0 0 variables.$dot-after-color;
  }

  8.333% {
    box-shadow:
      $x2 $y2 0 0 variables.$dot-before-color,
      $x1 $y1 0 0 variables.$dot-color,
      $x2 $y1 0 0 variables.$dot-after-color;
  }

  16.667% {
    box-shadow:
      $x2 $y2 0 0 variables.$dot-before-color,
      $x1 $y2 0 0 variables.$dot-color,
      $x2 $y1 0 0 variables.$dot-after-color;
  }

  25% {
    box-shadow:
      $x2 $y2 0 0 variables.$dot-before-color,
      $x1 $y2 0 0 variables.$dot-color,
      $x1 $y1 0 0 variables.$dot-after-color;
  }

  33.333% {
    box-shadow:
      $x2 $y1 0 0 variables.$dot-before-color,
      $x1 $y2 0 0 variables.$dot-color,
      $x1 $y1 0 0 variables.$dot-after-color;
  }

  41.667% {
    box-shadow:
      $x2 $y1 0 0 variables.$dot-before-color,
      $x2 $y2 0 0 variables.$dot-color,
      $x1 $y1 0 0 variables.$dot-after-color;
  }

  50% {
    box-shadow:
      $x2 $y1 0 0 variables.$dot-before-color,
      $x2 $y2 0 0 variables.$dot-color,
      $x1 $y2 0 0 variables.$dot-after-color;
  }

  58.333% {
    box-shadow:
      $x1 $y1 0 0 variables.$dot-before-color,
      $x2 $y2 0 0 variables.$dot-color,
      $x1 $y2 0 0 variables.$dot-after-color;
  }

  66.666% {
    box-shadow:
      $x1 $y1 0 0 variables.$dot-before-color,
      $x2 $y1 0 0 variables.$dot-color,
      $x1 $y2 0 0 variables.$dot-after-color;
  }

  75% {
    box-shadow:
      $x1 $y1 0 0 variables.$dot-before-color,
      $x2 $y1 0 0 variables.$dot-color,
      $x2 $y2 0 0 variables.$dot-after-color;
  }

  83.333% {
    box-shadow:
      $x1 $y2 0 0 variables.$dot-before-color,
      $x2 $y1 0 0 variables.$dot-color,
      $x2 $y2 0 0 variables.$dot-after-color;
  }

  91.667% {
    box-shadow:
      $x1 $y2 0 0 variables.$dot-before-color,
      $x1 $y1 0 0 variables.$dot-color,
      $x2 $y2 0 0 variables.$dot-after-color;
  }

  100% {
    box-shadow:
      $x1 $y2 0 0 variables.$dot-before-color,
      $x1 $y1 0 0 variables.$dot-color,
      $x2 $y1 0 0 variables.$dot-after-color;
  }
}
