@use "../list/_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/timeselector/_theme.scss" as *;


@mixin kendo-time-selector--theme() {

    @include kendo-time-selector--theme-base();

    // Time selector
    .k-timeselector {}


    // Time list
    .k-time-list {
        .k-item:hover {
            @include fill(
                $kendo-list-item-hover-text,
                $kendo-list-item-hover-bg
            );
        }
    }

}
