@use "@progress/kendo-theme-core/scss/components/fab/_layout.scss" as *;


@mixin kendo-fab--layout() {

    @include kendo-fab--layout-base();

    .k-fab-text {
        text-transform: uppercase;
    }

}
