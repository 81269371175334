@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-core/scss/components/slider/_theme.scss" as *;


@mixin kendo-slider--theme() {

    @include kendo-slider--theme-base();

    .k-slider {
        cursor: pointer;

        .k-slider-track,
        .k-slider-selection {
            border-radius: 0;
        }

        .k-tick::after {
            @include fill( $bg: $kendo-body-text );
        }

        .k-draghandle {
            box-sizing: border-box;

            &:focus {
                @include box-shadow( $kendo-slider-draghandle-focus-shadow );
            }
        }

        &.k-disabled {
            opacity: 1;

            .k-slider-track,
            .k-slider-selection,
            .k-draghandle {
                @include fill( $bg: $kendo-slider-track-bg );
            }

            .k-draghandle {
                border: 2px solid inherit;
            }
        }
    }

}
