@use "@progress/kendo-theme-core/scss/components/radio/_layout.scss" as *;


@mixin kendo-radio--layout() {

    @include kendo-radio--layout-base();

    .k-radio::before {
        transition: transform linear 280ms;
        transform-origin: 0 0;
    }

}
