@use "../core/_index.scss" as *;
@use "../button/_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/components/action-buttons/_theme.scss" as *;


@mixin kendo-action-buttons--theme() {

    @include kendo-action-buttons--theme-base();

    // Actions
    .k-actions {

        .k-button-solid-base {
            background-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));
            border-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));
        }

    }

}
