@use "../core/_index.scss" as *;

/// Horizontal padding of the popup.
/// @group popup
$kendo-popup-padding-x: null !default;
/// Vertical padding of the popup.
/// @group popup
$kendo-popup-padding-y: null !default;
/// Width of the border around the popup.
/// @group popup
$kendo-popup-border-width: 0px !default;
/// Border radius of the popup.
/// @group popup
$kendo-popup-border-radius: null !default;

/// Font size of the popup.
/// @group popup
$kendo-popup-font-size: var( --kendo-font-size, inherit ) !default;
/// Line height of the popup.
/// @group popup
$kendo-popup-line-height: var( --kendo-line-height, normal ) !default;

/// Horizontal padding of the popup content.
/// @group popup
$kendo-popup-content-padding-x: k-spacing(2) !default;
/// Vertical padding of the popup content.
/// @group popup
$kendo-popup-content-padding-y: k-spacing(2) !default;

/// Background color of the popup.
/// @group popup
$kendo-popup-bg: $kendo-component-bg !default;
/// Text color of the popup.
/// @group popup
$kendo-popup-text: $kendo-component-text !default;
/// Border color of the popup.
/// @group popup
$kendo-popup-border: $kendo-component-border !default;
/// Box shadow of the popup.
/// @group popup
$kendo-popup-shadow: k-elevation(4) !default;

@forward "@progress/kendo-theme-core/scss/components/popup/_variables.scss" with (
    $kendo-popup-padding-x: $kendo-popup-padding-x,
    $kendo-popup-padding-y: $kendo-popup-padding-y,
    $kendo-popup-border-width: $kendo-popup-border-width,
    $kendo-popup-border-radius: $kendo-popup-border-radius,
    $kendo-popup-font-size: $kendo-popup-font-size,
    $kendo-popup-line-height: $kendo-popup-line-height,
    $kendo-popup-content-padding-x: $kendo-popup-content-padding-x,
    $kendo-popup-content-padding-y: $kendo-popup-content-padding-y,
    $kendo-popup-bg: $kendo-popup-bg,
    $kendo-popup-text: $kendo-popup-text,
    $kendo-popup-border: $kendo-popup-border,
    $kendo-popup-shadow: $kendo-popup-shadow
);
