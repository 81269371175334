@use "./_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/drawer/_theme.scss" as *;


@mixin kendo-drawer--theme() {

    @include kendo-drawer--theme-base();

    .k-drawer-item {
        &.k-selected {

            &:focus,
            &.k-focus {
                background-color: $kendo-drawer-focus-bg;
                box-shadow: $kendo-drawer-focus-shadow;
            }
        }
    }

}
