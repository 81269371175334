@use "sass:math";
@use "../core/_index.scss" as *;
@use "../menu/_variables.scss" as *;
@use "../button/_variables.scss" as *;

// Toolbar

/// The horizontal padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-x: k-spacing(2) !default;
/// The horizontal padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-x: k-spacing(1) !default;
/// The horizontal padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-x: $kendo-toolbar-padding-x !default;
/// The horizontal padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-x: k-spacing(2.5) !default;

/// The vertical padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-y: k-spacing(2) !default;
/// The vertical padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-y: $kendo-toolbar-padding-y !default;
/// The vertical padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-y: k-spacing(2.5) !default;

/// The spacing between the Toolbar tools.
/// @group toolbar
$kendo-toolbar-spacing: k-spacing(2) !default;
/// The spacing between the tools of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-spacing: k-spacing(1.5) !default;
/// The spacing between the tools of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-spacing: $kendo-toolbar-spacing !default;
/// The spacing between the tools of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-spacing: k-spacing(2.5) !default;

/// The height of the Toolbar button separator.
/// @group toolbar
$kendo-toolbar-separator-height: $kendo-button-md-calc-size !default;
/// The height of the small Toolbar button separator.
/// @group toolbar
$kendo-toolbar-sm-separator-height: $kendo-button-sm-calc-size !default;
/// The height of the medium Toolbar button separator.
/// @group toolbar
$kendo-toolbar-md-separator-height: $kendo-toolbar-separator-height !default;
/// The height of the large Toolbar button separator.
/// @group toolbar
$kendo-toolbar-lg-separator-height: $kendo-button-lg-calc-size !default;

/// The width of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border-width: 0 !default;
/// The border radius of the Toolbar.
/// @group toolbar
$kendo-toolbar-border-radius: null !default;

/// The font family of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Toolbar.
/// @group toolbar
$kendo-toolbar-line-height: math.div( 20, 14 ) !default;

/// The background color of the Toolbar.
/// @group toolbar
$kendo-toolbar-bg: $kendo-component-bg !default;
/// The text color of the Toolbar.
/// @group toolbar
$kendo-toolbar-text: $kendo-component-text !default;
/// The color of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border: $kendo-component-border !default;
/// The gradient of the Toolbar.
/// @group toolbar
$kendo-toolbar-gradient: null !default;
/// The box shadow of the Toolbar.
/// @group toolbar
$kendo-toolbar-shadow: k-elevation(4) !default;
/// The left and right scroll overlay of the Toolbar.
/// @group toolbar
$kendo-toolbar-scroll-overlay: $kendo-toolbar-bg, if($kendo-enable-color-system, color-mix(in srgb, k-color( app-surface ) 0%, transparent), rgba( $kendo-color-white, 0)) !default;


/// The text color of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-text: unset !default;
/// The color of the border around the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-border: currentColor !default;
/// The gradient of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-gradient: null !default;
/// The box shadow of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-shadow: null !default;
/// The width of the border around the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-border-width: 1px !default;
/// The left and right scroll overlay of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-scroll-overlay: if($kendo-enable-color-system, k-color( app-surface ), $kendo-color-white), if($kendo-enable-color-system, color-mix(in srgb, k-color( app-surface ) 0%, transparent), rgba( $kendo-color-white, 0)) !default;


/// The text color of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-text: null !default;
/// The color of the border around the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-border: $kendo-toolbar-border !default;
/// The gradient of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-gradient: null !default;
/// The box shadow of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-shadow: 0 2px 0 -1px $kendo-component-border !default;
/// Border width of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-border-width: 1px !default;
/// The left and right scroll overlay of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-scroll-overlay: if($kendo-enable-color-system, k-color( app-surface ), $kendo-color-white), if($kendo-enable-color-system, color-mix(in srgb, k-color( app-surface ) 0%, transparent), rgba( $kendo-color-white, 0)) !default;


/// The color of the separator border of the Toolbar.
/// @group toolbar
$kendo-toolbar-separator-border: inherit !default;

/// The width of the input in the Toolbar.
/// @group toolbar
$kendo-toolbar-input-width: 10em !default;

/// The box shadow of the focused Toolbar item.
/// @group toolbar
$kendo-toolbar-item-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px rgba(0, 0, 0, .14), 0 1px 10px rgba(0, 0, 0, .12) !default;

/// The font size of the Toolbar overflow container.
/// @group toolbar
$kendo-toolbar-overflow-container-font-size: $kendo-menu-popup-md-font-size !default;
/// The line-height of the Toolbar overflow container.
/// @group toolbar
$kendo-toolbar-overflow-container-line-height: $kendo-menu-popup-md-line-height !default;
/// The vertical padding of the Toolbar overflow button.
/// @group toolbar
$kendo-toolbar-overflow-button-padding-y: $kendo-menu-popup-md-item-padding-y !default;
/// The horizontal padding of the Toolbar overflow button.
/// @group toolbar
$kendo-toolbar-overflow-button-padding-x: $kendo-menu-popup-md-item-padding-x !default;

/// The sizes map for the Toolbar.
/// @group toolbar
$kendo-toolbar-sizes: (
    sm: (
        padding-x: $kendo-toolbar-sm-padding-x,
        padding-y: $kendo-toolbar-sm-padding-y,
        spacing: $kendo-toolbar-sm-spacing,
        separator-height: $kendo-toolbar-sm-separator-height
    ),
    md: (
        padding-x: $kendo-toolbar-md-padding-x,
        padding-y: $kendo-toolbar-md-padding-y,
        spacing: $kendo-toolbar-md-spacing,
        separator-height: $kendo-toolbar-md-separator-height
    ),
    lg: (
        padding-x: $kendo-toolbar-lg-padding-x,
        padding-y: $kendo-toolbar-lg-padding-y,
        spacing: $kendo-toolbar-lg-spacing,
        separator-height: $kendo-toolbar-lg-separator-height
    )
) !default;

@forward "@progress/kendo-theme-core/scss/components/toolbar/_variables.scss" with (
    $kendo-toolbar-padding-x: $kendo-toolbar-padding-x,
    $kendo-toolbar-sm-padding-x: $kendo-toolbar-sm-padding-x,
    $kendo-toolbar-md-padding-x: $kendo-toolbar-md-padding-x,
    $kendo-toolbar-lg-padding-x: $kendo-toolbar-lg-padding-x,
    $kendo-toolbar-padding-y: $kendo-toolbar-padding-y,
    $kendo-toolbar-sm-padding-y: $kendo-toolbar-sm-padding-y,
    $kendo-toolbar-md-padding-y: $kendo-toolbar-md-padding-y,
    $kendo-toolbar-lg-padding-y: $kendo-toolbar-lg-padding-y,
    $kendo-toolbar-spacing: $kendo-toolbar-spacing,
    $kendo-toolbar-sm-spacing: $kendo-toolbar-sm-spacing,
    $kendo-toolbar-md-spacing: $kendo-toolbar-md-spacing,
    $kendo-toolbar-lg-spacing: $kendo-toolbar-lg-spacing,
    $kendo-toolbar-separator-height: $kendo-toolbar-separator-height,
    $kendo-toolbar-border-width: $kendo-toolbar-border-width,
    $kendo-toolbar-border-radius: $kendo-toolbar-border-radius,
    $kendo-toolbar-font-family: $kendo-toolbar-font-family,
    $kendo-toolbar-font-size: $kendo-toolbar-font-size,
    $kendo-toolbar-line-height: $kendo-toolbar-line-height,
    $kendo-toolbar-bg: $kendo-toolbar-bg,
    $kendo-toolbar-text: $kendo-toolbar-text,
    $kendo-toolbar-border: $kendo-toolbar-border,
    $kendo-toolbar-gradient: $kendo-toolbar-gradient,
    $kendo-toolbar-shadow: $kendo-toolbar-shadow,
    $kendo-toolbar-outline-text: $kendo-toolbar-outline-text,
    $kendo-toolbar-outline-border: $kendo-toolbar-outline-border,
    $kendo-toolbar-outline-gradient: $kendo-toolbar-outline-gradient,
    $kendo-toolbar-outline-shadow: $kendo-toolbar-outline-shadow,
    $kendo-toolbar-outline-border-width: $kendo-toolbar-outline-border-width,
    $kendo-toolbar-flat-text: $kendo-toolbar-flat-text,
    $kendo-toolbar-flat-border: $kendo-toolbar-flat-border,
    $kendo-toolbar-flat-gradient: $kendo-toolbar-flat-gradient,
    $kendo-toolbar-flat-shadow: $kendo-toolbar-flat-shadow,
    $kendo-toolbar-flat-border-width: $kendo-toolbar-flat-border-width,
    $kendo-toolbar-separator-border: $kendo-toolbar-separator-border,
    $kendo-toolbar-input-width: $kendo-toolbar-input-width,
    $kendo-toolbar-item-shadow: $kendo-toolbar-item-shadow,
    $kendo-toolbar-scroll-overlay: $kendo-toolbar-scroll-overlay,
    $kendo-toolbar-flat-scroll-overlay: $kendo-toolbar-flat-scroll-overlay,
    $kendo-toolbar-outline-scroll-overlay: $kendo-toolbar-outline-scroll-overlay,
    $kendo-toolbar-overflow-container-font-size: $kendo-toolbar-overflow-container-font-size,
    $kendo-toolbar-overflow-container-line-height: $kendo-toolbar-overflow-container-line-height,
    $kendo-toolbar-overflow-button-padding-y: $kendo-toolbar-overflow-button-padding-y,
    $kendo-toolbar-overflow-button-padding-x: $kendo-toolbar-overflow-button-padding-x,
    $kendo-toolbar-sizes: $kendo-toolbar-sizes
);
