@use "../../mixins/index.import.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-table--theme-base() {

    // Table
    .k-table,
    .k-data-table {
        border-color: $kendo-table-border;
        color: $kendo-table-text;
        background-color: $kendo-table-bg;

        .k-table-td-row-span {
            box-shadow: $kendo-table-cell-row-span-shadow;
        }
    }


    // Table header
    .k-table-thead,
    .k-table-header,
    .k-table-group-sticky-header {
        border-color: $kendo-table-header-border;
        color: $kendo-table-header-text;
        background-color: $kendo-table-header-bg;
    }


    // Table footer
    .k-table-tfoot,
    .k-table-footer {
        border-color: $kendo-table-footer-border;
        color: $kendo-table-footer-text;
        background-color: $kendo-table-footer-bg;
    }


    // Group row
    .k-table-group-row {
        border-color: $kendo-table-group-row-border;
        color: $kendo-table-group-row-text;
        background-color: $kendo-table-group-row-bg;
    }
    .k-table-group-td > span {
        color: $kendo-table-bg;
        background-color: $kendo-table-text;
    }


    // Alternating row
    .k-table-alt-row {
        border-color: $kendo-table-alt-row-border;
        color: $kendo-table-alt-row-text;
        background-color: $kendo-table-alt-row-bg;
    }


    // Hover state
    .k-table-tbody .k-table-row:hover,
    .k-table-list .k-table-row:hover,
    .k-table-tbody .k-table-row.k-hover,
    .k-table-list .k-table-row.k-hover {
        border-color: $kendo-table-hover-border;
        color: $kendo-table-hover-text;
        background-color: $kendo-table-hover-bg;
    }


    // Focus state
    .k-table-tbody .k-table-row,
    .k-table-list .k-table-row,
    .k-table-tbody .k-table-td,
    .k-table-list .k-table-td {
        &.k-focus,
        &:focus {
            border-color: $kendo-table-focus-border;
            color: $kendo-table-focus-text;
            background-color: $kendo-table-focus-bg;
            @include focus-indicator( $kendo-table-focus-shadow, inset );
        }
    }


    // Selected state
    .k-table-tbody .k-table-row.k-selected > .k-table-td,
    .k-table-list .k-table-row.k-selected {
        border-color: $kendo-table-selected-border;
        color: $kendo-table-selected-text;
        background-color: $kendo-table-selected-bg;
    }

}


@mixin kendo-table--theme() {
    @include kendo-table--theme-base();
}
