/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */

@use './variables';
@use './mixins';

$left-pos: -9999px;
$x1: - $left-pos - variables.$dot-spacing;
$x2: - $left-pos;
$x3: - $left-pos + variables.$dot-spacing;

.dot-typing {
  position: relative;
  left: $left-pos;

  @include mixins.dot;

  box-shadow:
    $x1 0 0 0 variables.$dot-before-color,
    $x2 0 0 0 variables.$dot-color,
    $x3 0 0 0 variables.$dot-after-color;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }

  16.667% {
    box-shadow:
      $x1 -10px 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }

  33.333% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }

  50% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 -10px 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }

  66.667% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }

  83.333% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 -10px 0 0 variables.$dot-after-color;
  }

  100% {
    box-shadow:
      $x1 0 0 0 variables.$dot-before-color,
      $x2 0 0 0 variables.$dot-color,
      $x3 0 0 0 variables.$dot-after-color;
  }
}
