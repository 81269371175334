@use "../grid/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/gantt/_theme.scss" as *;


@mixin kendo-gantt--theme() {

    @include kendo-gantt--theme-base();

    // Timeline
    .k-gantt-timeline-pane {

        tbody > tr:not(:only-child) > .k-header {
            border-left-color: $kendo-grid-border;
        }
    }

    .k-rtl .k-gantt-timeline-pane {
        .k-grid-header {
            tbody > tr:not(:only-child) > .k-header {
                border-right-color: $kendo-grid-border;
            }
        }
    }

}
