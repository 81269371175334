@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Splitter

/// The width of the border around the Splitter.
/// @group splitter
$kendo-splitter-border-width: 1px !default;
/// The font family of the Splitter.
/// @group splitter
$kendo-splitter-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Splitter.
/// @group splitter
$kendo-splitter-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Splitter.
/// @group splitter
$kendo-splitter-line-height: var( --kendo-line-height, normal ) !default;

/// The background color of the Splitter.
/// @group splitter
$kendo-splitter-bg: $kendo-component-bg !default;
/// The text color of the Splitter.
/// @group splitter
$kendo-splitter-text: $kendo-component-text !default;
/// The border color of the Splitter.
/// @group splitter
$kendo-splitter-border: $kendo-component-border !default;

/// The size of the Splitter split bar.
/// @group splitter
$kendo-splitter-splitbar-size: k-spacing(3) !default;
/// The length of the Splitter drag handle.
/// @group splitter
$kendo-splitter-drag-handle-length: 20px !default;
/// The thickness of the Splitter drag handle.
/// @group splitter
$kendo-splitter-drag-handle-thickness: 2px !default;
/// The margin of the Splitter drag handle icon.
/// @group splitter
$kendo-splitter-drag-icon-margin: k-spacing(2) !default;
/// The horizontal padding of the collapse icon in the Splitter.
/// @group splitter
$kendo-splitter-collapse-icon-padding-x: null !default;
/// The vertical padding of the collapse icon in the Splitter.
/// @group splitter
$kendo-splitter-collapse-icon-padding-y: k-spacing(0.5) !default;

/// The background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-splitter-bg, 1 )) !default;
/// The text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-text: $kendo-subtle-text !default;

/// The hover background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-hover-bg: if($kendo-enable-color-system, k-color( base-hover ), k-try-shade( $kendo-splitbar-bg, .5 )) !default;
/// The hover text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-hover-text: $kendo-splitbar-text !default;

/// The selected background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-selected-bg: $kendo-color-primary !default;
/// The selected text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-selected-text: $kendo-color-primary-contrast !default;

@forward "@progress/kendo-theme-core/scss/components/splitter/_variables.scss" with (
    $kendo-splitter-border-width: $kendo-splitter-border-width,
    $kendo-splitter-font-family: $kendo-splitter-font-family,
    $kendo-splitter-font-size: $kendo-splitter-font-size,
    $kendo-splitter-line-height: $kendo-splitter-line-height,
    $kendo-splitter-bg: $kendo-splitter-bg,
    $kendo-splitter-text: $kendo-splitter-text,
    $kendo-splitter-border: $kendo-splitter-border,
    $kendo-splitter-splitbar-size: $kendo-splitter-splitbar-size,
    $kendo-splitter-drag-handle-length: $kendo-splitter-drag-handle-length,
    $kendo-splitter-drag-handle-thickness: $kendo-splitter-drag-handle-thickness,
    $kendo-splitter-drag-icon-margin: $kendo-splitter-drag-icon-margin,
    $kendo-splitter-collapse-icon-padding-x: $kendo-splitter-collapse-icon-padding-x,
    $kendo-splitter-collapse-icon-padding-y: $kendo-splitter-collapse-icon-padding-y,
    $kendo-splitbar-bg: $kendo-splitbar-bg,
    $kendo-splitbar-text: $kendo-splitbar-text,
    $kendo-splitbar-hover-bg: $kendo-splitbar-hover-bg,
    $kendo-splitbar-hover-text: $kendo-splitbar-hover-text,
    $kendo-splitbar-selected-bg: $kendo-splitbar-selected-bg,
    $kendo-splitbar-selected-text: $kendo-splitbar-selected-text
);
