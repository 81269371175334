@use "sass:list";
@use "../window/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/dialog/_layout.scss" as *;


@mixin kendo-dialog--layout() {

    @include kendo-dialog--layout-base();

    // Dialog
    .k-dialog-content.k-scroll {
        border-width: 1px 0;
        border-style: solid;
        padding-top: #{list.slash( $kendo-window-inner-padding-y, 2 )};
        padding-bottom: #{list.slash( $kendo-window-inner-padding-y, 2 )};
    }

}
