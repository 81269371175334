@use "@progress/kendo-theme-core/scss/components/pivotgrid/_layout.scss" as *;


@mixin kendo-pivotgrid--layout() {

    @include kendo-pivotgrid--layout-base();

    .k-pivot-rowheaders .k-grid tr .k-grid-footer {
        border-bottom-width: 0;
    }

    .k-pivot .k-grid th,
    .k-pivot .k-grid td {
        border-left-color: inherit;
    }

}
