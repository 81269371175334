@use "./_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "../floating-label/_variables.scss" as *;
@use "@progress/kendo-theme-core/scss/components/scheduler/_theme.scss" as *;


@mixin kendo-scheduler--theme() {

    @include kendo-scheduler--theme-base();

    .k-scheduler-layout td.k-selected,
    .k-scheduler-layout .k-scheduler-cell.k-selected {
        background-color: if($kendo-enable-color-system, color-mix(in srgb, k-color( secondary ) 25%, transparent), rgba($kendo-selected-bg, .25));
    }

    // Agenda view
    .k-scheduler-agendaview {
        .k-scheduler-content tr.k-selected {
            background-color: if($kendo-enable-color-system, color-mix(in srgb, k-color( secondary ) 25%, transparent), rgba($kendo-selected-bg, .25));
        }
    }

    // Events
    .k-event {

        .k-event-actions {
            .k-link {
                color: $kendo-scheduler-event-bg;
                background-color: $kendo-scheduler-event-text;
            }
        }

        .k-resize-handle::before {
            // TODO: $kendo-scheduler-content-area-bg
            background-color: $kendo-color-primary-contrast;
        }
    }

    .k-scheduler-edit-form {
        .k-edit-label {
            color: $kendo-floating-label-text;
        }
    }

}
