@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// ProgressBar

/// The height of the ProgressBar.
/// @group progressbar
$kendo-progressbar-height: 5px !default;
/// The horizontal width of the ProgressBar.
/// @group progressbar
$kendo-progressbar-horizontal-width: 100% !default;
/// The animation timing of the ProgressBar.
/// @group progressbar
$kendo-progressbar-animation-timing: unset !default;
/// The width of the border around the ProgressBar.
/// @group progressbar
$kendo-progressbar-border-width: 0px !default;
/// The font family of the ProgressBar.
/// @group progressbar
$kendo-progressbar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the ProgressBar.
/// @group progressbar
$kendo-progressbar-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the ProgressBar.
/// @group progressbar
$kendo-progressbar-line-height: var( --kendo-line-height-xs, normal ) !default;

/// The background color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-bg: if($kendo-enable-color-system, k-color( primary-subtle ), k-try-tint( $kendo-color-primary, 8 )) !default;
/// The text color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-text: $kendo-component-text !default;
/// The border color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-border: unset !default;
/// The background gradient of the ProgressBar.
/// @group progressbar
$kendo-progressbar-gradient: null !default;

/// The progress background color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-value-bg: $kendo-color-primary !default;
/// The progress text color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-value-text: $kendo-color-primary-contrast !default;
/// The progress border color of the ProgressBar.
/// @group progressbar
$kendo-progressbar-value-border: unset !default;
/// The progress background gradient of the ProgressBar.
/// @group progressbar
$kendo-progressbar-value-gradient: null !default;

/// The background color of the indeterminate ProgressBar.
/// @group progressbar
$kendo-progressbar-indeterminate-bg: $kendo-progressbar-bg !default;
/// The text color of the indeterminate ProgressBar.
/// @group progressbar
$kendo-progressbar-indeterminate-text: $kendo-progressbar-text !default;
/// The border color of the indeterminate ProgressBar.
/// @group progressbar
$kendo-progressbar-indeterminate-border: $kendo-progressbar-border !default;
/// The background gradient of the indeterminate ProgressBar.
/// @group progressbar
$kendo-progressbar-indeterminate-gradient: null !default;

/// The border color of the chunk ProgressBar.
/// @group progressbar
$kendo-progressbar-chunk-border: $kendo-component-bg !default;

// Circular Progressbar
/// The arc stroke color of the circular ProgressBar.
/// @group progressbar
$kendo-circular-progressbar-arc-stroke: $kendo-color-primary !default;
/// The scale stroke background color of the circular ProgressBar.
/// @group progressbar
$kendo-circular-progressbar-scale-stroke: $kendo-progressbar-bg !default;

@forward "@progress/kendo-theme-core/scss/components/progressbar/_variables.scss" with (
    $kendo-progressbar-height: $kendo-progressbar-height,
    $kendo-progressbar-horizontal-width: $kendo-progressbar-horizontal-width,
    $kendo-progressbar-animation-timing: $kendo-progressbar-animation-timing,
    $kendo-progressbar-border-width: $kendo-progressbar-border-width,
    $kendo-progressbar-font-family: $kendo-progressbar-font-family,
    $kendo-progressbar-font-size: $kendo-progressbar-font-size,
    $kendo-progressbar-line-height: $kendo-progressbar-line-height,
    $kendo-progressbar-bg: $kendo-progressbar-bg,
    $kendo-progressbar-text: $kendo-progressbar-text,
    $kendo-progressbar-border: $kendo-progressbar-border,
    $kendo-progressbar-gradient: $kendo-progressbar-gradient,
    $kendo-progressbar-value-bg: $kendo-progressbar-value-bg,
    $kendo-progressbar-value-text: $kendo-progressbar-value-text,
    $kendo-progressbar-value-border: $kendo-progressbar-value-border,
    $kendo-progressbar-value-gradient: $kendo-progressbar-value-gradient,
    $kendo-progressbar-indeterminate-bg: $kendo-progressbar-indeterminate-bg,
    $kendo-progressbar-indeterminate-text: $kendo-progressbar-indeterminate-text,
    $kendo-progressbar-indeterminate-border: $kendo-progressbar-indeterminate-border,
    $kendo-progressbar-indeterminate-gradient: $kendo-progressbar-indeterminate-gradient,
    $kendo-progressbar-chunk-border: $kendo-progressbar-chunk-border,
    $kendo-circular-progressbar-arc-stroke: $kendo-circular-progressbar-arc-stroke,
    $kendo-circular-progressbar-scale-stroke: $kendo-circular-progressbar-scale-stroke
);
